
import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

export class ArticleSitemapModel extends HYNetworkModel {

  [immerable] = true;
  id: number;
  updatedAt: string;

  constructor(data:any) {
    super();
    this.id = data?.id ?? 0;
    this.updatedAt = data?.updated_at ?? '';
  }
}