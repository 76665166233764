import { reduxUserReducer } from "@/modules/user/redux/reducer";
import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reduxCommonStorageReducer } from "./common-storage/reducer";

const rootReducer = combineReducers({
  user: reduxUserReducer,
  storage: reduxCommonStorageReducer,
});
type RootAppState = ReturnType<typeof rootReducer>;
const rootConfig: PersistConfig<RootAppState> = {
  key: "huiyi",
  storage: storage,
  version: 1000,
};

const reducer = persistReducer(rootConfig, rootReducer);
export default reducer;
