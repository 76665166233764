
import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

export class UserModel {
  [immerable] = true;
  // 账户Id
  id: number;
  // 是否是公司员工
  isStaff: boolean;
  // 手机号
  mobile: string;
  // 真实姓名
  name: string;
  // 昵称
  nickname: string;
  // QQ号
  qq: number;
  // 微信号
  wechat: string;
  // 简介
  brief: string;
  // 余额（分）
  balance: number;
  // 上次登陆时间
  lastLoginAt: string;

  constructor(data:any) {
    this.id = data?.id ?? 0;
    this.isStaff = data?.is_staff ?? false;
    this.mobile = data?.mobile ?? '';
    this.name = data?.name ?? '';
    this.nickname = data?.nickname ?? '';
    this.brief = data?.brief_introduction ?? '';
    this.balance = data?.balance ?? 0;
    this.lastLoginAt = data?.last_login_at ?? '';
    this.qq = data?.qq ?? 0;
    this.wechat = data?.wechat_number ?? '';
  }
}

export class LoginModel extends HYNetworkModel {

  [immerable] = true;

  token: string;
  user: UserModel;

  constructor(data:any) {
    super();
    this.token = data?.token ?? '';
    this.user = new UserModel(data?.user_info);
  }
}