import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

export class PaymentAlipayModel extends HYNetworkModel {
  [immerable] = true;
  // 订单编号
  tradeNumber: string;
  // 支付链接
  payURL: string;

  constructor(data: any) {
    super();
    this.tradeNumber = data?.out_trade_no ?? "";
    this.payURL = data?.pay_url ?? "";
  }
}
