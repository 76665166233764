
import {
  HYModuleManager,
  HYNetworkManager,
  HYStorage,
} from "@hy-sdk/framework";
import { hyNetworkBusiness } from "../network/business";
import { hyNetworkInstance } from "../network/instance";
import { hyStorageInstance } from "../storage/instance";
import ModuleUser from "@/modules/user";
import { enableAllPlugins } from "immer";
import ModuleTM from "@/modules/tm";
import ModuleCMS from "@/modules/cms";
import ModuleOthers from "@/modules/others";

let inifFlag = false;

/**
 * 项目初始化流程
 */
export function hyAppInit() {

  if (inifFlag === true) return;
  inifFlag = true;
  
  // ------------ 三方库 ------------

  // Immer
  enableAllPlugins();

  // ------------ 框架 ------------

  // 存储实现
  HYStorage.setInstance(hyStorageInstance);

  // 网络业务
  HYNetworkManager.setRequestInstance(hyNetworkInstance);
  HYNetworkManager.addBusiness(hyNetworkBusiness);

  // ------------ 业务 ------------
  
  // 模块注册
  HYModuleManager.register(ModuleUser.module);
  HYModuleManager.register(ModuleTM.module);
  HYModuleManager.register(ModuleCMS.module);
  HYModuleManager.register(ModuleOthers.module);
}
