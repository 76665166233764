
export class WebLinkModel {

  id: number;
  name: string;
  url: string;
  order: number;

  constructor(data:any) {
    this.id = data?.id ?? 0;
    this.name = data?.name ?? '';
    this.url = data?.url ?? '';
    this.order = data?.order ?? 0;
  }
}