
import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";
import { FundPriceType, FundRelationType } from "../../defines/fund-defines";

export class PaymentOrderInfoModel {

  [immerable] = true;

  // 内部订单编号
  orderNo: string;
  // 订金
  deposit: number;
  // 已付
  payment: number;
  // 已退
  refund: number;
  // 总金额
  total: number;

  constructor(data: any) {
    this.orderNo = data?.order_no ?? "";
    this.deposit = data?.deposit_price ?? 0;
    this.deposit /= 100;
    this.payment = data?.payment_amount ?? 0;
    this.payment /= 100;
    this.refund = data?.refund_price ?? 0;
    this.refund /= 100;
    this.total = data?.total_price ?? 0;
    this.total /= 100;
  }
}

export class PaymentInfoModel extends HYNetworkModel {
  [immerable] = true;
  // 业务订单号
  outTradeNo: string;
  // 单子名字
  subject: string;
  // 过期时间
  expiredAt: string;

  // 客户姓名
  username: string;
  // 客户手机号
  mobile: string;
  // 金额
  price: number;
  // 金额类型
  priceType: FundPriceType;
  // 支付宝支付页面
  payUrl: string;

  // 关联类型
  relationType: FundRelationType;
  // 是否是自定义标
  isCustom: boolean;
  // 商标分类
  trademarkClassify: number;
  // 商标名字
  trademarkName: string;
  // 商标图
  trademarkPhoto: string;

  // 内部订单关联信息
  order: PaymentOrderInfoModel;

  constructor(data: any) {

    super();
    this.outTradeNo = data?.out_trade_no ?? "";
    this.subject = data?.subject ?? "";
    this.expiredAt = data?.expired_at ?? "";
    this.username = data?.user_name ?? "";
    this.mobile = data?.mobile ?? "";

    this.price = data?.price ?? 0;
    this.price /= 100;
    this.priceType = data?.price_type ?? 0;
    this.payUrl = data?.pay_url ?? "";

    this.relationType = data?.relation_type ?? 0;
    this.isCustom = data?.is_custom ?? false;
    this.trademarkClassify = data?.trademark_classify ?? 0;
    this.trademarkName = data?.trademark_name ?? "";
    this.trademarkPhoto = data?.trademark_photo_url ?? "";

    this.order = new PaymentOrderInfoModel(data?.order_info);
  }
}
