import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

// 主场景假数据
class FakeHome {

  [immerable] = true;

  transactionCount: string;
  transactionPrice : string;
  newShop: string;
  totalHelpUser: string;
  newBrandAuth : string;
  // 动态交易信息
  transactionDynamics: string[][];

  constructor(data:any) {
    this.transactionCount = data?.home_transaction_count ?? '';
    this.transactionPrice = data?.home_transaction_price ?? '';
    this.newShop = data?.home_new_shop ?? '';
    this.totalHelpUser = data?.home_total_help_user ?? '';
    this.newBrandAuth = data?.home_new_brand_auth ?? '';
    this.transactionDynamics = data?.home_transaction_dynamics ?? [];
  }
}

// 商标转移
class FakeTMTransfer {

  [immerable] = true;

  customerSatisfaction: string;
  monthTransaction: string;
  monthlyIncreases: string;

  constructor(data:any) {
    this.customerSatisfaction = data?.trademark_transfer_customer_satisfaction ?? '';
    this.monthTransaction = data?.trademark_transfer_month_transaction ?? '';
    this.monthlyIncreases = data?.trademark_transfer_monthly_increases ?? '';
  }
}

// 商标注册
class FakeTMRegister {

  [immerable] = true;

  totalTransaction: string;
  lastMonthTransaction: string;
  successRate: string;
  searchUser: string;
  searchCount: string;
  getReceiptUser: string;

  constructor(data:any) {
    this.totalTransaction = data?.trademark_register_total_transaction ?? '';
    this.lastMonthTransaction = data?.trademark_register_last_month_transaction ?? '';
    this.successRate = data?.trademark_register_success_rate ?? '';
    this.searchUser = data?.trademark_register_user ?? '';
    this.searchCount = data?.trademark_register_search_count ?? '';
    this.getReceiptUser = data?.trademark_register_get_receipt ?? '';
  }
}

// 假数据配置
export default class FakeDataModel extends HYNetworkModel {

  [immerable] = true;

  home: FakeHome;
  tmTransfer: FakeTMTransfer;
  tmRegister: FakeTMRegister;

  constructor(data:any) {

    super();
    data = JSON.parse(data);
    this.home = new FakeHome(data);
    this.tmTransfer = new FakeTMTransfer(data);
    this.tmRegister = new FakeTMRegister(data);
  }
}