import { ListModel } from "@/common/defines/list-model";
import { hyNetworkBusiness } from "@/common/network/business";
import { HYNetworkManager, HYNetworkRequest, HYNetworkResponse } from "@hy-sdk/framework";
import { IFeedbackParams, ICooperationParams } from "./models/params";
import { ArticleSitemapModel } from "./models/article-sitemap-model";
import OthersURL from "./url";
import CheckIfRealModel from "./models/check-if-real-model";
import FakeDataModel from "./models/fake-data-model";
import { TeamPersonModel } from "./models/team-person-model";
import { WebLinkModel } from "./models/web-link-model";

const businessId = hyNetworkBusiness.id;

namespace OthersApi {
  /**
   * 意见反馈
   */
  export function feedback(params: IFeedbackParams) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: OthersURL.Common.feedback,
      })
    ).response;
  }

  /**
   * 获取文章sitemap
   */
  export function articleSiteMap() {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: OthersURL.Common.siteMapArticle,
        converter: (data) => new ListModel(data, ArticleSitemapModel, true),
      })
    ).response;
  }

  /**
   * 商务合作
   */
  export function cooperation(params: ICooperationParams) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: OthersURL.Info.cooperation,
        data: params,
      })
    ).response;
  }

  /**
   * 验证真假客服
   */
  export function checkIfReal(code: string) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: OthersURL.checkIfReal(code),
        converter: (data) => new CheckIfRealModel(data),
      })
    ).response;
  }

  /**
   * 假数据
   */
  export function fakeData() {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: OthersURL.fakeData,
        converter: (data) => new FakeDataModel(data),
      })
    ).response;
  }

  /**
   * 销售团队
   */
  export function teamPerson(onLoadCache?: (response: HYNetworkResponse) => void) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: OthersURL.saleTeam,
        cache: {
          enable: true,
          duration: 24 * 60 * 60 * 1000,
        },
        converter: (data) => new ListModel(data, TeamPersonModel, true),
      }),
      onLoadCache
    ).response;
  }

  /** 获取友情链接 */
  export function webLink() {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: 'GET',
        path: OthersURL.webLink,
        converter: (data) => new ListModel(data, WebLinkModel, true),
      })
    ).response;
  }
}

export default OthersApi;
