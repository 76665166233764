import { Reducer } from "redux";
import { ReduxCommonStorageActionType } from "./action";

export interface IReduxCommonStorageState {
  [key: string]: any;
}

const initState: IReduxCommonStorageState = {};

export const reduxCommonStorageReducer: Reducer<IReduxCommonStorageState, ReduxCommonStorageActionType> = (
  preState = initState,
  action
) => {
  switch (action.type) {
    case "common-storage.set": {
      const newState = { ...preState };
      newState[action.key] = action.value;
      return newState;
    }

    case "common-storage.delete": {
      const newState = { ...preState };
      delete newState[action.key];
      return newState;
    }

    case "common-storage.clear": {
      return initState;
    }

    default: {
      return preState;
    }
  }
};
