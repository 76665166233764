
import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

export default class OrderStatisticsModel extends HYNetworkModel {

  [immerable] = true;
  pendingNum: number;
  tradingNum: number;
  totalNum: number;

  constructor(data:any) {
    super();
    this.pendingNum = data?.wait_to_pay_order_count ?? 0;
    this.tradingNum = data?.trading_order_count ?? 0;
    this.totalNum = data?.total_order_count ?? 0;
  }
}
