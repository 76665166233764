import {
  HYNetworkParser,
  HYNetworkRequest,
  HYNetworkResponse,
  HYNetworkError,
  HYNetworkModel,
} from "@hy-sdk/framework";
import { AxiosError, AxiosResponse } from "axios";
import { ErrorCode, ErrorCodeTools } from "./error";

/**
 * 网络框架，业务解析器
 */
export const hyNetworkParser: HYNetworkParser = {
  /**
   *  处理服务端返回数据
   */
  handleOriginResponse: async function (
    request: HYNetworkRequest,
    response: any
  ): Promise<HYNetworkResponse> {
    let model: any, failedModel: any;
    let error: HYNetworkError | undefined;

    const origionResponse = response as AxiosResponse;
    // 遵循服务端规则，如有变动，需要跟随调整
    const { errcode, errmsg, data } = origionResponse.data;
    if (errcode === ErrorCode.Success) {
      if (request.converter) {
        model = request.converter(data);
      }
    } else {
      error = {
        code: errcode,
        message: errmsg,
      };
      if (request.failedConverter) {
        failedModel = request.failedConverter(data);
      }
    }

    return new HYNetworkResponse({
      header: origionResponse.headers as any,
      data: origionResponse.data,
      model,
      failedModel,
      error,
      errorDescribe: error ? ErrorCodeTools.translateErrorCode(error.code) : undefined,
    });
  },

  /**
   * 处理网络框架异常错误
   */
  handleExceptionResponse: async function (
    _: HYNetworkRequest,
    error: any
  ): Promise<HYNetworkResponse> {

    const axiosError: AxiosError = error;
    let errorCode: ErrorCode;
    switch (axiosError.code) {
      case AxiosError.ETIMEDOUT:
      case AxiosError.ECONNABORTED:
        errorCode = ErrorCode.RequestTimeout;
        break;
      case AxiosError.ERR_NETWORK:
        errorCode = ErrorCode.RequestNetConnect;
        break;
      default:
        errorCode = ErrorCode.RequestError;
    }

    return new HYNetworkResponse({
      header: {},
      error: {
        code: errorCode,
        message: error.toString(),
      },
      errorDescribe: ErrorCodeTools.translateErrorCode(errorCode),
    });
  },

  handleCacheData: function (
    request: HYNetworkRequest<HYNetworkModel, HYNetworkModel>,
    cacheData: any
  ): HYNetworkResponse<HYNetworkModel, HYNetworkModel> {
    let model: any, failedModel: any;
    let error: HYNetworkError | undefined;
    // 遵循服务端规则，如有变动，需要跟随调整
    const { err_code, err_msg, data } = cacheData;
    if (err_code === ErrorCode.Success) {
      if (request.converter) {
        model = request.converter(data);
      }
    } else {
      error = {
        code: err_code,
        message: err_msg,
      };
      if (request.failedConverter) {
        failedModel = request.failedConverter(data);
      }
    }

    return new HYNetworkResponse({
      isCache: true,
      header: {},
      data: cacheData,
      model,
      failedModel,
      error,
      errorDescribe: error ? ErrorCodeTools.translateErrorCode(error.code) : undefined,
    });
  },
};
