import { TMOrderStatus } from "../../defines/order-defines";
import { TMSubClassifyModel } from "../tm/tm-model";
import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

// 订单 - 商标信息
export class TMOrderGoodsModel {

  [immerable] = true;
  
  // 渲染图
  renderPhoto: string;
  // 原图
  photo: string;
  // 编号
  code: string;
  // 标名
  name: string;
  // 大类
  classify: number;
  // 子分类
  subClassify: TMSubClassifyModel[];

  constructor(data:any) {
    
    this.renderPhoto = data?.render_photo_url ?? '';
    this.photo = data?.trademark_photo_url ?? '';
    this.code = data?.item_code ?? '';
    this.name = data?.trademark_name ?? '';
    this.classify = data?.trademark_classify ?? 0;
    this.subClassify = [];
    for (var obj of data?.extend_goods ?? []) {
      this.subClassify.push(new TMSubClassifyModel(obj));
    }
  }
}

// 订单 - 卖家信息
export class TMOrderSellerModel {

  [immerable] = true;
  name: string;
  mobile: string;
  
  constructor(data:any) {
    this.name = data?.name ?? '';
    this.mobile = data?.mobile ?? '';
  }
}

// 订单信息
export class TMOrderModel extends HYNetworkModel {

  [immerable] = true;
  key: number;
  // 订单Id
  orderId: number;
  // 订单编号
  orderNum: string;
  // 订单状态
  status: TMOrderStatus;
  // 订金，分
  depositPrice: number;
  // 订金，显示值，元
  get depositPriceDisplay() {
    return this.depositPrice / 100;
  }
  // 总金额，分
  totalPrice: number;
  // 总金额，显示值，元
  get totalPriceDisplay() {
    return this.totalPrice / 100;
  }
  // 已付金额，分
  paymentPrice: number;
  // 已付金额，显示值，元
  get paymentPriceDisplay() {
    return this.paymentPrice / 100;
  }
  // 退款金额，分
  refundPrice: number;
  // 退款金额，显示值，元
  get refundPriceDisplay() {
    return this.refundPrice / 100;
  }
  // 商品
  goods: TMOrderGoodsModel[];

  // 卖家
  seller: TMOrderSellerModel;

  // 创建时间
  createdAt: string;
  // 支付时间
  paymentAt: string;
  // 退款时间
  refundAt: string;
  // 完结时间
  finishAt: string;

  constructor(data:any) {

    super();
    this.orderId = data?.order_id ?? 0;
    this.key = this.orderId;
    this.orderNum = data?.order_no ?? '';
    this.status = data?.status ?? TMOrderStatus.PendingPayment;
    this.depositPrice = data?.deposit_price ?? 0;
    this.totalPrice = data?.total_price ?? 0;
    this.paymentPrice = data?.payment_amount ?? 0;
    this.refundPrice = data?.refund_price ?? 0;
    this.goods = [];
    for (const obj of data?.goods ?? []) {
      this.goods.push(new TMOrderGoodsModel(obj));
    }

    this.seller = new TMOrderSellerModel(data?.sale_info);

    this.createdAt = data?.created_at ?? '';
    this.paymentAt = data?.payment_at ?? '';
    this.refundAt = data?.refund_at ?? '';
    this.finishAt = data?.finish_at ?? '';
  }
}
