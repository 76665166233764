
namespace TMURL {

  const prefix = "/frontend/trademark";

  // GET 首页展示的商标
  export function homeList(type: 0 | 1) {
    return prefix + "/home-page/" + type;
  }
  // POST 搜索
  export const search = prefix + "/search-v2";
  // GET 精品商标
  export const recommend = prefix + "/recommend";
  // GET 商标详情
  export function detail (code: string) {
    return prefix + "/detail/" + code;
  }
  // GET 获取同名商标
  export function sameName(name: string) {
    return prefix + "/same-name/" + name;
  }

  /**
   * 订单
   */
  export namespace Order {

    const orderPrefix = prefix + "/order";
    // POST 列表
    export const list = orderPrefix + "/list";
    // POST 创建
    export const create = orderPrefix + "/create";
    // POST 取消
    export const cancel = orderPrefix + "/cancel";
  }
}

export default TMURL;