
// 分类下属子类
export interface ITMSubCategory {
  code: string;
  name: string;
}

// 商标注册类型
export enum TMRegisterType {
  // 不限
  Any,
  // 中文,
  CN,
  // 英文,
  EN,
  // 中文+英文
  CNEN,
  // 图形，
  Graph,
  // 数字
  Number,
  // 中文+图形
  CNGraph,
  // 英文+图形
  ENGraph,
  // 中文+英文+图形
  CNENGraph,
}

// 商标名字长度
export enum TMNameLengthType {
  // 不限
  Any,
  // 1-2字
  Word1To2,
  // 3字
  Word3,
  // 4字
  Word4,
  // 5字
  Word5,
  // 5字以上
  Above5,
}

// 注册年限
export enum TMRegisterTime {
  // 不限
  Any,
  // 一年以内
  Year1,
  // 一到两年
  Year1To2,
  // 两到三年
  Year2To3,
  // 三年以上
  YearAbove3,
}

// 商标排序参数
export enum TMOrderByType {
  // 创建时间降序
  CreateTimeDown,
  // 创建时间升序
  CreateTimeUp,
  // 价格升序
  PriceUp,
  // 价格降序
  PriceDown,
}

// 商标相关定义
export default class TMDefines {
  // 45分类
  static readonly Categories = [
    "化工原料",
    "涂料油漆",
    "日用化品",
    "燃料油脂",
    "医药品",
    "五金金属",
    "机械设备",
    "手工器械",
    "科学仪器",
    "医疗器械",
    "灯具空间",
    "运输工具",
    "军火烟火",
    "珠宝钟表",
    "乐器",
    "办公用品",
    "橡胶制品",
    "皮革皮具",
    "建筑材料",
    "家具",
    "厨房洁具",
    "绳网袋篷",
    "纱线丝",
    "布料床单",
    "服装鞋帽",
    "纽扣拉链",
    "地毯席垫",
    "健身器材",
    "食品",
    "方便食品",
    "饲料种籽",
    "啤酒饮料",
    "酒",
    "烟草烟具",
    "广告销售",
    "金融物管",
    "建筑修理",
    "通讯服务",
    "运输贮藏",
    "材料加工",
    "教育娱乐",
    "网站服务",
    "餐饮住宿",
    "医疗园艺",
    "社会服务",
  ];

  /**
   * 分类下属子类定义
   * 
   * 0 - 44对应1-45大类
   */
   static readonly SubCategories: ITMSubCategory[][] = [
    [
      {
        code: "0101",
        name: "工业气体，单质",
      },
      {
        code: "0102",
        name: "用于工业、科学、农业、园艺、林业的工业化工原料",
      },
      {
        code: "0103",
        name: "放射性元素及其化学品",
      },
      {
        code: "0104",
        name: "用于工业、科学的化学品、化学制剂，不属于其他类别的产品用的化学制品",
      },
      {
        code: "0105",
        name: "用于农业、园艺、林业的化学品、化学制剂",
      },
      {
        code: "0106",
        name: "化学试剂",
      },
      {
        code: "0107",
        name: "摄影用化学用品及材料",
      },
      {
        code: "0108",
        name: "未加工的人造合成树脂，未加工塑料物质（不包括未加工的天然树脂）",
      },
      {
        code: "0109",
        name: "肥料",
      },
      {
        code: "0110",
        name: "灭火、防火用合成物",
      },
      {
        code: "0111",
        name: "淬火用化学制剂",
      },
      {
        code: "0112",
        name: "焊接用化学制剂",
      },
      {
        code: "0113",
        name: "食药用化学品（不包括食品用防腐盐）",
      },
      {
        code: "0114",
        name: "鞣料及皮革用化学品",
      },
      {
        code: "0115",
        name: "工业用黏合剂和胶（不包括纸用黏合剂）",
      },
      {
        code: "0116",
        name: "纸浆",
      },
    ],
    [
      {
        code: "0201",
        name: "染料，媒染剂（不包括食用）",
      },
      {
        code: "0202",
        name: "颜料（不包括食用、绝缘用），绘画、装饰、印刷和艺术用金属箔及金属粉",
      },
      {
        code: "0203",
        name: "食品着色剂",
      },
      {
        code: "0204",
        name: "油墨",
      },
      {
        code: "0205",
        name: "涂料，油漆及附料（不包括绝缘漆）",
      },
      {
        code: "0206",
        name: "防锈剂，木材防腐剂",
      },
      {
        code: "0207",
        name: "未加工的天然树脂",
      },
    ],
    [
      {
        code: "0301",
        name: "肥皂，香皂及其他人用洗洁物品，洗衣用漂白剂及其他物料",
      },
      {
        code: "0302",
        name: "清洁、去渍用制剂",
      },
      {
        code: "0303",
        name: "抛光、擦亮制剂",
      },
      {
        code: "0304",
        name: "研磨用材料及其制剂",
      },
      {
        code: "0305",
        name: "香料，香精油",
      },
      {
        code: "0306",
        name: "化妆品（不包括动物用化妆品）",
      },
      {
        code: "0307",
        name: "牙膏，洗牙用制剂",
      },
      {
        code: "0308",
        name: "熏料",
      },
      {
        code: "0309",
        name: "动物用化妆品",
      },
      {
        code: "0310",
        name: "室内芳香剂",
      },
    ],
    [
      {
        code: "0401",
        name: "工业用油及油脂，润滑油，润滑剂（不包括燃料用油）",
      },
      {
        code: "0402",
        name: "液体、气体燃料和照明燃料",
      },
      {
        code: "0403",
        name: "固体燃料",
      },
      {
        code: "0404",
        name: "工业用蜡",
      },
      {
        code: "0405",
        name: "照明用蜡烛和灯芯",
      },
      {
        code: "0406",
        name: "吸收、润湿和黏结灰尘用合成物",
      },
      {
        code: "0407",
        name: "能源",
      },
    ],
    [
      {
        code: "0501",
        name: "药品，消毒剂，中药药材，药酒",
      },
      {
        code: "0502",
        name: "医用营养品，人用膳食补充剂，婴儿食品",
      },
      {
        code: "0503",
        name: "净化制剂",
      },
      {
        code: "0504",
        name: "兽药，动物用膳食补充剂",
      },
      {
        code: "0505",
        name: "杀虫剂，除莠剂，农药",
      },
      {
        code: "0506",
        name: "卫生用品，绷敷材料，医用保健袋",
      },
      {
        code: "0507",
        name: "填塞牙孔用料，牙科用蜡",
      },
      {
        code: "0508",
        name: "单一商品",
      },
    ],
    [
      {
        code: "0601",
        name: "普通金属及其合金、板、各种型材（不包括焊接及铁路用金属材料）",
      },
      {
        code: "0602",
        name: "普通金属管及其配件",
      },
      {
        code: "0603",
        name: "金属建筑材料，可移动金属建筑物（不包括建筑小五金）",
      },
      {
        code: "0604",
        name: "铁路用金属材料",
      },
      {
        code: "0605",
        name: "非电气用缆索和金属线、网、带",
      },
      {
        code: "0606",
        name: "缆绳用非电气金属附件",
      },
      {
        code: "0607",
        name: "钉及标准紧固件",
      },
      {
        code: "0608",
        name: "家具及门窗的金属附件",
      },
      {
        code: "0609",
        name: "日用五金器具",
      },
      {
        code: "0610",
        name: "非电子锁",
      },
      {
        code: "0611",
        name: "保险箱柜",
      },
      {
        code: "0612",
        name: "金属器具，金属硬件（非机器零件）",
      },
      {
        code: "0613",
        name: "金属容器",
      },
      {
        code: "0614",
        name: "金属标牌",
      },
      {
        code: "0615",
        name: "动物用金属制品",
      },
      {
        code: "0616",
        name: "焊接用金属材料（不包括塑料焊丝）",
      },
      {
        code: "0617",
        name: "锚，停船用金属浮动船坞，金属下锚桩",
      },
      {
        code: "0618",
        name: "手铐，金属制身份鉴别手环",
      },
      {
        code: "0619",
        name: "（测气象或风力的）金属风标",
      },
      {
        code: "0620",
        name: "金属植物保护器",
      },
      {
        code: "0621",
        name: "捕野兽陷阱",
      },
      {
        code: "0622",
        name: "普通金属艺术品，青铜（艺术品）",
      },
      {
        code: "0623",
        name: "矿石，矿砂",
      },
      {
        code: "0624",
        name: "金属棺（埋葬用），金属棺材扣件，棺材用金属器材",
      },
    ],
    [
      {
        code: "0701",
        name: "农业用机械及部件（不包括小农具）",
      },
      {
        code: "0702",
        name: "渔牧业用机械及器具",
      },
      {
        code: "0703",
        name: "伐木、锯木、木材加工及火柴生产用机械及器具",
      },
      {
        code: "0704",
        name: "造纸及加工纸制品工业用机械及器具",
      },
      {
        code: "0705",
        name: "印刷工业用机械及器具",
      },
      {
        code: "0706",
        name: "纤维加工及纺织、针织工业用机械及部件",
      },
      {
        code: "0707",
        name: "印染工业用机械",
      },
      {
        code: "0708",
        name: "制茶工业用机械",
      },
      {
        code: "0709",
        name: "食品业用机械及部件",
      },
      {
        code: "0710",
        name: "酿造、饮料工业用机械",
      },
      {
        code: "0711",
        name: "烟草工业用机械",
      },
      {
        code: "0712",
        name: "皮革工业用机械",
      },
      {
        code: "0713",
        name: "缝纫、制鞋工业用机械",
      },
      {
        code: "0714",
        name: "自行车工业用设备",
      },
      {
        code: "0715",
        name: "陶瓷、砖、瓦制造机械",
      },
      {
        code: "0716",
        name: "工业用雕刻、打标机械",
      },
      {
        code: "0717",
        name: "制电池机械",
      },
      {
        code: "0718",
        name: "日用杂品加工机械",
      },
      {
        code: "0719",
        name: "制搪瓷机械",
      },
      {
        code: "0720",
        name: "制灯泡机械",
      },
      {
        code: "0721",
        name: "包装机械（不包括成套设备专用包装机械）",
      },
      {
        code: "0722",
        name: "民用煤加工机械",
      },
      {
        code: "0723",
        name: "厨房家用器具（不包括烹调、电气加热设备及厨房手工具）",
      },
      {
        code: "0724",
        name: "洗衣机",
      },
      {
        code: "0725",
        name: "制药工业用机械及部件",
      },
      {
        code: "0726",
        name: "橡胶、塑料工业机械",
      },
      {
        code: "0727",
        name: "玻璃工业用机械",
      },
      {
        code: "0728",
        name: "化肥设备",
      },
      {
        code: "0729",
        name: "其他化学工业用机械",
      },
      {
        code: "0730",
        name: "地质勘探、采矿、选矿用机械",
      },
      {
        code: "0731",
        name: "冶炼工业用设备",
      },
      {
        code: "0732",
        name: "石油开采、精炼工业用设备",
      },
      {
        code: "0733",
        name: "建筑、铁道、土木工程用机械",
      },
      {
        code: "0734",
        name: "起重运输机械",
      },
      {
        code: "0735",
        name: "锻压设备",
      },
      {
        code: "0736",
        name: "铸造机械",
      },
      {
        code: "0737",
        name: "蒸汽动力设备",
      },
      {
        code: "0738",
        name: "内燃动力设备",
      },
      {
        code: "0739",
        name: "风力、水力动力设备",
      },
      {
        code: "0740",
        name: "制办公用针钉机械",
      },
      {
        code: "0741",
        name: "制纽扣拉链机械",
      },
      {
        code: "0742",
        name: "金属切削机床，切削工具和其他金属加工机械",
      },
      {
        code: "0743",
        name: "非手动的手持工具",
      },
      {
        code: "0744",
        name: "静电、电子工业用设备",
      },
      {
        code: "0745",
        name: "光学工业用设备",
      },
      {
        code: "0746",
        name: "气体分离设备",
      },
      {
        code: "0747",
        name: "喷漆机具",
      },
      {
        code: "0748",
        name: "发电机，非陆地车辆用马达和引擎，马达和引擎零部件",
      },
      {
        code: "0749",
        name: "泵，阀，气体压缩机，风机，液压元件，气动元件",
      },
      {
        code: "0750",
        name: "机器传动用联轴节，传动带及其他机器零部件",
      },
      {
        code: "0751",
        name: "焊接机械",
      },
      {
        code: "0752",
        name: "清洁、废物处理机械",
      },
      {
        code: "0753",
        name: "单一商品",
      },
      {
        code: "0754",
        name: "电镀设备",
      },
    ],
    [
      {
        code: "0801",
        name: "手动研磨器具",
      },
      {
        code: "0802",
        name: "小农具（不包括农业、园艺用刀剪）",
      },
      {
        code: "0803",
        name: "林业、园艺用手工具",
      },
      {
        code: "0804",
        name: "畜牧业用手工具",
      },
      {
        code: "0805",
        name: "渔业用手工具",
      },
      {
        code: "0806",
        name: "理发工具，修指甲刀，文身器具",
      },
      {
        code: "0807",
        name: "非动力手工具（不包括刀、剪）",
      },
      {
        code: "0808",
        name: "非动力手工器具",
      },
      {
        code: "0809",
        name: "专业用手工具",
      },
      {
        code: "0810",
        name: "刀剪（不包括机械刀片，文具刀）",
      },
      {
        code: "0811",
        name: "除火器外的随身武器",
      },
      {
        code: "0812",
        name: "餐具刀、叉、匙",
      },
      {
        code: "0813",
        name: "手工具柄",
      },
    ],
    [
      {
        code: "0901",
        name: "电子计算机及其外部设备",
      },
      {
        code: "0902",
        name: "记录、记数检测器",
      },
      {
        code: "0903",
        name: "其他办公用机械（不包括打字机、誊写机、油印机）",
      },
      {
        code: "0904",
        name: "衡器",
      },
      {
        code: "0905",
        name: "量具",
      },
      {
        code: "0906",
        name: "信号器具",
      },
      {
        code: "0907",
        name: "通信导航设备",
      },
      {
        code: "0908",
        name: "音像设备",
      },
      {
        code: "0909",
        name: "摄影、电影用具及仪器",
      },
      {
        code: "0910",
        name: "测量仪器仪表，实验室用器具，电测量仪器，科学仪器",
      },
      {
        code: "0911",
        name: "光学仪器",
      },
      {
        code: "0912",
        name: "光电传输材料",
      },
      {
        code: "0913",
        name: "电器用晶体及碳素材料，电子、电气通用元件",
      },
      {
        code: "0914",
        name: "电器成套设备及控制装置",
      },
      {
        code: "0915",
        name: "电解装置",
      },
      {
        code: "0916",
        name: "灭火器具",
      },
      {
        code: "0918",
        name: "工业用X光机械设备",
      },
      {
        code: "0919",
        name: "安全救护器具",
      },
      {
        code: "0920",
        name: "警报装置，电铃",
      },
      {
        code: "0921",
        name: "眼镜及附件",
      },
      {
        code: "0922",
        name: "电池，充电器",
      },
      {
        code: "0923",
        name: "电影片，已曝光材料",
      },
      {
        code: "0924",
        name: "单一商品",
      },
    ],
    [
      {
        code: "1001",
        name: "外科、医疗和兽医用仪器、器械、设备，不包括电子、核子、电疗、医疗用Ｘ光设备、器械及仪器",
      },
      {
        code: "1002",
        name: "牙科设备及器具",
      },
      {
        code: "1003",
        name: "医疗用电子、核子、电疗和Ｘ光设备",
      },
      {
        code: "1004",
        name: "医疗用辅助器具、设备和用品",
      },
      {
        code: "1005",
        name: "奶嘴，奶瓶",
      },
      {
        code: "1006",
        name: "性用品",
      },
      {
        code: "1007",
        name: "假肢，假发和假器官",
      },
      {
        code: "1008",
        name: "矫形矫正及助行用品",
      },
      {
        code: "1009",
        name: "缝合用材料",
      },
    ],
    [
      {
        code: "1101",
        name: "照明用设备、器具（不包括汽灯、油灯）",
      },
      {
        code: "1103",
        name: "汽灯，油灯",
      },
      {
        code: "1104",
        name: "烹调及民用电气加热设备（不包括厨房用手工用具，食品加工机器）",
      },
      {
        code: "1105",
        name: "制冷、冷藏设备（不包括冷藏车）",
      },
      {
        code: "1106",
        name: "干燥、通风、空调设备（包括冷暖房设备），气体净化设备",
      },
      {
        code: "1107",
        name: "加温、蒸汽设备（包括工业用炉、锅炉，不包括机车锅炉、锅驼机锅炉、蒸汽机锅炉）",
      },
      {
        code: "1108",
        name: "水暖管件",
      },
      {
        code: "1109",
        name: "卫生设备（不包括盥洗室用具）",
      },
      {
        code: "1110",
        name: "消毒和净化设备",
      },
      {
        code: "1111",
        name: "小型取暖器",
      },
      {
        code: "1112",
        name: "不属别类的打火器具",
      },
      {
        code: "1113",
        name: "核能反应设备",
      },
    ],
    [
      {
        code: "1201",
        name: "火车及其零部件",
      },
      {
        code: "1202",
        name: "汽车、电车、摩托车及其零部件（不包括轮胎）",
      },
      {
        code: "1204",
        name: "自行车、三轮车及其零部件（不包括轮胎）",
      },
      {
        code: "1205",
        name: "缆车，架空运输设备",
      },
      {
        code: "1206",
        name: "轮椅，手推车，儿童推车",
      },
      {
        code: "1207",
        name: "畜力车辆，雪橇",
      },
      {
        code: "1208",
        name: "轮胎及轮胎修理工具",
      },
      {
        code: "1209",
        name: "空用运载工具（不包括飞机轮胎）",
      },
      {
        code: "1210",
        name: "水用运载工具",
      },
      {
        code: "1211",
        name: "运载工具零部件",
      },
    ],
    [
      {
        code: "1301",
        name: "火器，军火及子弹",
      },
      {
        code: "1302",
        name: "爆炸物",
      },
      {
        code: "1303",
        name: "烟火，爆竹",
      },
      {
        code: "1304",
        name: "个人防护用喷雾",
      },
    ],
    [
      {
        code: "1401",
        name: "贵金属及其合金",
      },
      {
        code: "1402",
        name: "贵金属盒",
      },
      {
        code: "1403",
        name: "珠宝，首饰，宝石及贵金属制纪念品",
      },
      {
        code: "1404",
        name: "钟，表，计时器及其零部件",
      },
    ],
    [
      {
        code: "1501",
        name: "乐器",
      },
      {
        code: "1502",
        name: "乐器辅助用品及配件",
      },
    ],
    [
      {
        code: "1601",
        name: "工业用纸",
      },
      {
        code: "1602",
        name: "技术用纸（不包括绝缘纸）",
      },
      {
        code: "1603",
        name: "生活用纸",
      },
      {
        code: "1604",
        name: "纸板",
      },
      {
        code: "1605",
        name: "办公、日用纸制品",
      },
      {
        code: "1606",
        name: "印刷出版物",
      },
      {
        code: "1607",
        name: "照片，图片，图画",
      },
      {
        code: "1609",
        name: "纸及不属别类的塑料包装物品",
      },
      {
        code: "1610",
        name: "办公装订、切削用具",
      },
      {
        code: "1611",
        name: "办公文具（不包括笔，墨，印，胶水）",
      },
      {
        code: "1612",
        name: "墨，砚",
      },
      {
        code: "1613",
        name: "印章，印油",
      },
      {
        code: "1614",
        name: "笔",
      },
      {
        code: "1615",
        name: "办公或家庭用胶带或黏合剂",
      },
      {
        code: "1616",
        name: "办公室用绘图仪器，绘画仪器",
      },
      {
        code: "1617",
        name: "绘画用具（不包括绘图仪器，笔）",
      },
      {
        code: "1618",
        name: "打字机、誊写机、油印机及其附件（包括印刷铅字、印版）",
      },
      {
        code: "1619",
        name: "教学用具（不包括教学实验用仪器）",
      },
      {
        code: "1620",
        name: "室内模型物（不包括教学用模型标本）",
      },
    ],
    [
      {
        code: "1701",
        name: "不属别类的橡胶，古塔胶，树胶",
      },
      {
        code: "1702",
        name: "非金属密封减震制品",
      },
      {
        code: "1703",
        name: "橡胶，树脂，纤维制品",
      },
      {
        code: "1704",
        name: "软管",
      },
      {
        code: "1705",
        name: "保温、隔热、隔音材料",
      },
      {
        code: "1706",
        name: "绝缘用材料及其制品",
      },
      {
        code: "1707",
        name: "包装、填充用材料（包括橡胶、塑料制品）",
      },
      {
        code: "1708",
        name: "单一商品",
      },
    ],
    [
      {
        code: "1801",
        name: "皮革和人造皮革，裘皮",
      },
      {
        code: "1802",
        name: "不属别类的皮革、人造皮革制品，箱子及旅行袋，日用革制品",
      },
      {
        code: "1804",
        name: "雨伞及其部件",
      },
      {
        code: "1805",
        name: "手杖",
      },
      {
        code: "1806",
        name: "动物用具",
      },
    ],
    [
      {
        code: "1901",
        name: "半成品木材",
      },
      {
        code: "1902",
        name: "土，沙，石，石料，灰泥，炉渣等建筑用料",
      },
      {
        code: "1903",
        name: "石膏",
      },
      {
        code: "1904",
        name: "水泥",
      },
      {
        code: "1905",
        name: "水泥预制构件",
      },
      {
        code: "1906",
        name: "建筑砖瓦",
      },
      {
        code: "1907",
        name: "非金属耐火材料及制品",
      },
      {
        code: "1908",
        name: "柏油，沥青及制品",
      },
      {
        code: "1909",
        name: "非金属建筑材料及构件（不包括水泥预制构件）",
      },
      {
        code: "1910",
        name: "非金属建筑物",
      },
      {
        code: "1911",
        name: "建筑用玻璃及玻璃材料",
      },
      {
        code: "1912",
        name: "建筑用涂层",
      },
      {
        code: "1913",
        name: "建筑用黏合料",
      },
      {
        code: "1914",
        name: "石、混凝土、大理石雕塑品",
      },
      {
        code: "1915",
        name: "棺椁墓碑",
      },
    ],
    [
      {
        code: "2001",
        name: "家具",
      },
      {
        code: "2002",
        name: "非金属容器及附件",
      },
      {
        code: "2003",
        name: "不属别类的工业、建筑配件",
      },
      {
        code: "2004",
        name: "镜子、画框及部件",
      },
      {
        code: "2005",
        name: "不属别类的竹、藤、棕、草制品",
      },
      {
        code: "2006",
        name: "未加工或半加工的骨、角、牙、介及不属别类的工艺品",
      },
      {
        code: "2007",
        name: "非金属牌照",
      },
      {
        code: "2008",
        name: "食品用塑料装饰品",
      },
      {
        code: "2009",
        name: "禽、畜等动物用制品",
      },
      {
        code: "2010",
        name: "非金属制身份鉴别手环",
      },
      {
        code: "2011",
        name: "非金属棺材及附件",
      },
      {
        code: "2012",
        name: "非金属家具附件",
      },
      {
        code: "2013",
        name: "垫，枕",
      },
      {
        code: "2014",
        name: "非金属紧固件及门窗附件",
      },
    ],
    [
      {
        code: "2101",
        name: "厨房炊事用具及容器（包括不属别类的餐具）",
      },
      {
        code: "2102",
        name: "不属别类的玻璃器皿",
      },
      {
        code: "2103",
        name: "瓷器，陶器（茶具，酒具除外）",
      },
      {
        code: "2104",
        name: "玻璃、瓷、陶的工艺品",
      },
      {
        code: "2105",
        name: "茶具、酒具、咖啡具及饮水用具",
      },
      {
        code: "2106",
        name: "家庭日用及卫生器具",
      },
      {
        code: "2107",
        name: "梳子，刷子（不包括牙刷），制刷原料",
      },
      {
        code: "2108",
        name: "刷牙用具",
      },
      {
        code: "2109",
        name: "牙签",
      },
      {
        code: "2110",
        name: "化妆用具",
      },
      {
        code: "2111",
        name: "隔热用具",
      },
      {
        code: "2112",
        name: "家务用具",
      },
      {
        code: "2113",
        name: "未加工或半加工玻璃（不包括建筑用玻璃）",
      },
      {
        code: "2114",
        name: "不属别类的动植物器具",
      },
      {
        code: "2115",
        name: "家用灭虫、灭鼠用具",
      },
    ],
    [
      {
        code: "2201",
        name: "缆，绳，线，带",
      },
      {
        code: "2202",
        name: "网，遮篷，帐篷，防水帆布，帆",
      },
      {
        code: "2203",
        name: "袋子，装卸、包装用物品",
      },
      {
        code: "2204",
        name: "衬垫，填充料，密封物品（不包括橡胶、塑料制品）",
      },
      {
        code: "2205",
        name: "纤维原料",
      },
    ],
    [
      {
        code: "2301",
        name: "纺织用纱、丝",
      },
      {
        code: "2302",
        name: "线",
      },
      {
        code: "2303",
        name: "毛线",
      },
    ],
    [
      {
        code: "2401",
        name: "纺织品，布料",
      },
      {
        code: "2402",
        name: "特殊用织物",
      },
      {
        code: "2403",
        name: "纺织品壁挂",
      },
      {
        code: "2404",
        name: "毡及毡制品",
      },
      {
        code: "2405",
        name: "毛巾，浴巾，手帕",
      },
      {
        code: "2406",
        name: "床上用品",
      },
      {
        code: "2407",
        name: "室内遮盖物",
      },
      {
        code: "2409",
        name: "特殊用布",
      },
      {
        code: "2410",
        name: "纺织品制或塑料制旗",
      },
      {
        code: "2411",
        name: "寿衣",
      },
    ],
    [
      {
        code: "2501",
        name: "衣物",
      },
      {
        code: "2502",
        name: "婴儿纺织用品",
      },
      {
        code: "2503",
        name: "特种运动服装",
      },
      {
        code: "2504",
        name: "不透水服装",
      },
      {
        code: "2505",
        name: "戏装",
      },
      {
        code: "2507",
        name: "鞋",
      },
      {
        code: "2508",
        name: "帽",
      },
      {
        code: "2509",
        name: "袜",
      },
      {
        code: "2510",
        name: "手套（不包括特种手套）",
      },
      {
        code: "2511",
        name: "领带，围巾，披巾，面纱",
      },
      {
        code: "2512",
        name: "腰带，服装带",
      },
      {
        code: "2513",
        name: "单一商品",
      },
    ],
    [
      {
        code: "2601",
        name: "花边，饰品及编带",
      },
      {
        code: "2602",
        name: "不属别类的服饰品，饰针",
      },
      {
        code: "2603",
        name: "纽扣，领钩扣，拉链",
      },
      {
        code: "2604",
        name: "假发，假胡须",
      },
      {
        code: "2605",
        name: "缝纫用具（线除外）",
      },
      {
        code: "2606",
        name: "假花",
      },
      {
        code: "2607",
        name: "硬托衬骨",
      },
      {
        code: "2608",
        name: "修补纺织品用热粘胶片",
      },
      {
        code: "2609",
        name: "亚麻布标记用品",
      },
    ],
    [
      {
        code: "2701",
        name: "地毯",
      },
      {
        code: "2702",
        name: "席类",
      },
      {
        code: "2703",
        name: "垫及其他可移动铺地板用品",
      },
      {
        code: "2704",
        name: "墙纸，非纺织品墙帷及非纺织品壁挂",
      },
    ],
    [
      {
        code: "2801",
        name: "娱乐器械，娱乐物品",
      },
      {
        code: "2802",
        name: "玩具",
      },
      {
        code: "2803",
        name: "棋，牌及辅助器材",
      },
      {
        code: "2804",
        name: "球类及器材",
      },
      {
        code: "2805",
        name: "健身器材",
      },
      {
        code: "2806",
        name: "射箭运动器材",
      },
      {
        code: "2807",
        name: "体操、举重、田径、冰雪及属于本类的其他运动器材",
      },
      {
        code: "2808",
        name: "游泳池及跑道",
      },
      {
        code: "2809",
        name: "运动防护器具及冰鞋",
      },
      {
        code: "2810",
        name: "圣诞树用的装饰品",
      },
      {
        code: "2811",
        name: "钓具",
      },
      {
        code: "2812",
        name: "单一商品",
      },
    ],
    [
      {
        code: "2901",
        name: "肉，非活的家禽，野味，肉汁",
      },
      {
        code: "2902",
        name: "非活水产品",
      },
      {
        code: "2903",
        name: "罐头食品（软包装食品不包括在内，随原料制成品归组）",
      },
      {
        code: "2904",
        name: "腌渍、干制水果及制品",
      },
      {
        code: "2905",
        name: "腌制、干制蔬菜",
      },
      {
        code: "2906",
        name: "蛋品",
      },
      {
        code: "2907",
        name: "奶及乳制品",
      },
      {
        code: "2908",
        name: "食用油脂",
      },
      {
        code: "2910",
        name: "食用果胶",
      },
      {
        code: "2911",
        name: "加工过的坚果",
      },
      {
        code: "2912",
        name: "菌类干制品",
      },
      {
        code: "2913",
        name: "食物蛋白，豆腐制品",
      },
      {
        code: "2914",
        name: "肠衣",
      },
    ],
    [
      {
        code: "3001",
        name: "咖啡，咖啡代用品，可可",
      },
      {
        code: "3002",
        name: "茶、茶饮料",
      },
      {
        code: "3003",
        name: "糖",
      },
      {
        code: "3004",
        name: "糖果，南糖，糖",
      },
      {
        code: "3005",
        name: "蜂蜜，蜂王浆等营养食品",
      },
      {
        code: "3006",
        name: "面包，糕点",
      },
      {
        code: "3007",
        name: "方便食品",
      },
      {
        code: "3008",
        name: "米，面粉（包括五谷杂粮）",
      },
      {
        code: "3009",
        name: "面条及米面制品",
      },
      {
        code: "3010",
        name: "谷物膨化食品",
      },
      {
        code: "3012",
        name: "食用淀粉及其制品",
      },
      {
        code: "3013",
        name: "食用冰，冰制品",
      },
      {
        code: "3014",
        name: "食盐",
      },
      {
        code: "3015",
        name: "酱油，醋",
      },
      {
        code: "3016",
        name: "芥末，味精，沙司，酱等调味品",
      },
      {
        code: "3017",
        name: "酵母",
      },
      {
        code: "3018",
        name: "食用香精，香料",
      },
      {
        code: "3019",
        name: "单一商品",
      },
    ],
    [
      {
        code: "3101",
        name: "未加工的林业产品",
      },
      {
        code: "3102",
        name: "未加工的谷物及农产品（不包括蔬菜，种子）",
      },
      {
        code: "3103",
        name: "花卉，园艺产品，草本植物",
      },
      {
        code: "3104",
        name: "活动物",
      },
      {
        code: "3105",
        name: "未加工的水果及干果",
      },
      {
        code: "3106",
        name: "新鲜蔬菜",
      },
      {
        code: "3107",
        name: "种子",
      },
      {
        code: "3108",
        name: "动物饲料",
      },
      {
        code: "3109",
        name: "麦芽",
      },
      {
        code: "3110",
        name: "动物栖息用干草等制品",
      },
    ],
    [
      {
        code: "3201",
        name: "啤酒",
      },
      {
        code: "3202",
        name: "不含酒精饮料",
      },
      {
        code: "3203",
        name: "糖浆及其他供饮料用的制剂",
      },
    ],
    [
      {
        code: "3301",
        name: "含酒精的饮料（啤酒除外）",
      },
    ],
    [
      {
        code: "3401",
        name: "烟草及其制品",
      },
      {
        code: "3402",
        name: "烟具",
      },
      {
        code: "3403",
        name: "火柴",
      },
      {
        code: "3404",
        name: "吸烟用打火机",
      },
      {
        code: "3405",
        name: "烟纸，过滤嘴",
      },
      {
        code: "3406",
        name: "香烟用调味品",
      },
      {
        code: "3407",
        name: "电子香烟及其部件",
      },
    ],
    [
      {
        code: "3501",
        name: "广告",
      },
      {
        code: "3502",
        name: "工商管理辅助业",
      },
      {
        code: "3503",
        name: "替他人推销",
      },
      {
        code: "3504",
        name: "人事管理辅助业",
      },
      {
        code: "3505",
        name: "商业企业迁移",
      },
      {
        code: "3506",
        name: "办公事务",
      },
      {
        code: "3507",
        name: "财会",
      },
      {
        code: "3508",
        name: "单一服务",
      },
      {
        code: "3509",
        name: "药品、医疗用品零售或批发服务",
      },
    ],
    [
      {
        code: "3601",
        name: "保险",
      },
      {
        code: "3602",
        name: "金融事务",
      },
      {
        code: "3603",
        name: "珍品估价",
      },
      {
        code: "3604",
        name: "不动产事务",
      },
      {
        code: "3605",
        name: "海关经纪",
      },
      {
        code: "3606",
        name: "担保",
      },
      {
        code: "3607",
        name: "慈善募捐",
      },
      {
        code: "3608",
        name: "受托管理",
      },
      {
        code: "3609",
        name: "典当",
      },
    ],
    [
      {
        code: "3701",
        name: "建设、维修信息服务",
      },
      {
        code: "3702",
        name: "建筑工程服务",
      },
      {
        code: "3703",
        name: "开采服务",
      },
      {
        code: "3704",
        name: "建筑物装饰修理服务",
      },
      {
        code: "3705",
        name: "供暖设备的安装与修理",
      },
      {
        code: "3706",
        name: "机械、电器设备的安装与修理",
      },
      {
        code: "3707",
        name: "陆地机械车辆维修",
      },
      {
        code: "3708",
        name: "飞机维修",
      },
      {
        code: "3709",
        name: "造船服务",
      },
      {
        code: "3710",
        name: "影视器材维修",
      },
      {
        code: "3711",
        name: "钟表修理",
      },
      {
        code: "3712",
        name: "保险装置的维修",
      },
      {
        code: "3713",
        name: "特殊处理服务",
      },
      {
        code: "3714",
        name: "轮胎维修服务",
      },
      {
        code: "3715",
        name: "家具的修复、保养",
      },
      {
        code: "3716",
        name: "衣服、皮革的修补、保护、洗涤服务",
      },
      {
        code: "3717",
        name: "灭虫，消毒服务",
      },
      {
        code: "3718",
        name: "单一服务",
      },
    ],
    [
      {
        code: "3801",
        name: "进行播放无线电或电视节目的服务",
      },
      {
        code: "3802",
        name: "通信服务",
      },
    ],
    [
      {
        code: "3901",
        name: "运输及运输前的包装服务",
      },
      {
        code: "3902",
        name: "水上运输及相关服务",
      },
      {
        code: "3903",
        name: "陆地运输",
      },
      {
        code: "3904",
        name: "空中运输",
      },
      {
        code: "3905",
        name: "其他运输及相关服务",
      },
      {
        code: "3906",
        name: "货物的贮藏",
      },
      {
        code: "3907",
        name: "潜水工具出租",
      },
      {
        code: "3908",
        name: "供水电气服务",
      },
      {
        code: "3909",
        name: "水闸管理服务",
      },
      {
        code: "3910",
        name: "投递服务",
      },
      {
        code: "3911",
        name: "旅行安排",
      },
      {
        code: "3912",
        name: "单一服务",
      },
    ],
    [
      {
        code: "4001",
        name: "综合加工及提供信息服务",
      },
      {
        code: "4002",
        name: "金属材料处理或加工服务",
      },
      {
        code: "4003",
        name: "纺织品化学处理或加工服务",
      },
      {
        code: "4004",
        name: "木材加工服务",
      },
      {
        code: "4005",
        name: "纸张加工服务",
      },
      {
        code: "4006",
        name: "玻璃加工服务",
      },
      {
        code: "4007",
        name: "陶器加工服务",
      },
      {
        code: "4008",
        name: "食物、饮料加工服务",
      },
      {
        code: "4009",
        name: "剥制加工服务",
      },
      {
        code: "4010",
        name: "皮革、服装加工服务",
      },
      {
        code: "4011",
        name: "影像加工处理服务",
      },
      {
        code: "4012",
        name: "污物处理服务",
      },
      {
        code: "4013",
        name: "空气调节服务",
      },
      {
        code: "4014",
        name: "水处理服务",
      },
      {
        code: "4015",
        name: "单一服务",
      },
    ],
    [
      {
        code: "4101",
        name: "教育",
      },
      {
        code: "4102",
        name: "组织和安排教育、文化、娱乐等活动",
      },
      {
        code: "4103",
        name: "图书馆服务",
      },
      {
        code: "4104",
        name: "出版服务",
      },
      {
        code: "4105",
        name: "文娱、体育活动的服务",
      },
      {
        code: "4106",
        name: "驯兽",
      },
      {
        code: "4107",
        name: "单一服务",
      },
    ],
    [
      {
        code: "4209",
        name: "提供科学技术研究服务",
      },
      {
        code: "4210",
        name: "提供地质调查、研究、开发服务",
      },
      {
        code: "4211",
        name: "提供化学研究服务",
      },
      {
        code: "4212",
        name: "提供生物学、医学研究服务",
      },
      {
        code: "4213",
        name: "提供气象情报服务",
      },
      {
        code: "4214",
        name: "提供测试服务",
      },
      {
        code: "4216",
        name: "外观设计服务",
      },
      {
        code: "4217",
        name: "建筑物的设计、咨询服务",
      },
      {
        code: "4218",
        name: "服装设计服务",
      },
      {
        code: "4220",
        name: "计算机编程及相关服务",
      },
      {
        code: "4224",
        name: "提供艺术品鉴定服务",
      },
      {
        code: "4227",
        name: "单一服务",
      },
    ],
    [
      {
        code: "4301",
        name: "提供餐饮，住宿服务",
      },
      {
        code: "4302",
        name: "提供房屋设施的服务",
      },
      {
        code: "4303",
        name: "养老院",
      },
      {
        code: "4304",
        name: "托儿服务",
      },
      {
        code: "4305",
        name: "为动物提供食宿",
      },
      {
        code: "4306",
        name: "单一服务",
      },
    ],
    [
      {
        code: "4401",
        name: "医疗服务",
      },
      {
        code: "4402",
        name: "卫生、美容服务",
      },
      {
        code: "4403",
        name: "为动物提供服务",
      },
      {
        code: "4404",
        name: "农业、园艺服务",
      },
      {
        code: "4405",
        name: "单一服务",
      },
    ],
    [
      {
        code: "4501",
        name: "安全服务",
      },
      {
        code: "4502",
        name: "提供人员服务",
      },
      {
        code: "4503",
        name: "提供服饰服务",
      },
      {
        code: "4504",
        name: "殡仪服务",
      },
      {
        code: "4505",
        name: "单一服务",
      },
      {
        code: "4506",
        name: "法律服务",
      },
    ],
  ];

  // 翻译商标分类，如： 01类 化工原料
  static readonly GetClassifyValue = (index: number): string => {
    if (index < 1 || index > 45) return "未知";

    return `${index < 10 ? "0" : ""}${index}-${
      TMDefines.Categories[index - 1]
    }`;
  };

  // 翻译 - 商标搜索，名字长度
  static GetTMSearchNameLengthValue (value: TMNameLengthType) {
    switch (value) {
      case TMNameLengthType.Word1To2: return '1-2字';
      case TMNameLengthType.Word3: return '3字';
      case TMNameLengthType.Word4: return '4字';
      case TMNameLengthType.Word5: return '5字';
      case TMNameLengthType.Above5: return '5字以上';
    }
  }

  // 选项 - 商标搜索，名字长度
  static readonly GetTMSearchNameLengthOptions = [
    { type: TMNameLengthType.Word1To2, value: "1-2字" },
    { type: TMNameLengthType.Word3, value: "3字" },
    { type: TMNameLengthType.Word4, value: "4字" },
    { type: TMNameLengthType.Word5, value: "5字" },
    { type: TMNameLengthType.Above5, value: "5字以上" },
  ];

  // 翻译 - 商标搜索，注册类型
  static GetTMRegTypeValue (value: TMRegisterType) {
    switch (value) {
      case TMRegisterType.CN: return '中文';
      case TMRegisterType.EN: return '英文';
      case TMRegisterType.CNEN: return '中文+英文';
      case TMRegisterType.Graph: return '图形';
      case TMRegisterType.Number: return '数字';
      case TMRegisterType.CNGraph: return '中文+图形';
      case TMRegisterType.ENGraph: return '英文+图形';
      case TMRegisterType.CNENGraph: return '中文+英文+图形';
    }
  }

  // 选项 - 商标搜索，注册类型
  static readonly GetTMRegTypeOptions = [
    { type: TMRegisterType.CN, value: "中文" },
    { type: TMRegisterType.EN, value: "英文" },
    { type: TMRegisterType.CNEN, value: "中文+英文" },
    { type: TMRegisterType.Graph, value: "图形" },
    { type: TMRegisterType.Number, value: "数字" },
    // { type: TMRegisterType.CNGraph, value: "中文+图形" },
    // { type: TMRegisterType.ENGraph, value: "英文+图形" },
    // { type: TMRegisterType.CNENGraph, value: "中文+英文+图形" },
  ];

  // 翻译 - 商标搜索，注册类型
  static GetTMRegTimeValue (value: TMRegisterTime) {
    switch (value) {
      case TMRegisterTime.Year1: return '1年以内';
      case TMRegisterTime.Year1To2: return '1-2年';
      case TMRegisterTime.Year2To3: return '2-3年';
      case TMRegisterTime.Year2To3: return '3年以上';
    }
  }

  // 选项 - 商标搜索，注册年限
  static readonly GetTMRegTimeOptions = [
    { type: TMRegisterTime.Year1, value: "1年以内" },
    { type: TMRegisterTime.Year1To2, value: "1-2年" },
    { type: TMRegisterTime.Year2To3, value: "2-3年" },
    { type: TMRegisterTime.Year2To3, value: "3年以上" },
  ];
}
