import type { AppProps } from "next/app";
import { PersistGate } from "redux-persist/integration/react";
import { appStorePersist, appStore } from "@/common/redux";
import HYTheme from "@/common/styles/hy-theme";
import { HYEnv } from "@hy-sdk/framework";
import Script from "next/script";
import { useRouter } from "next/router";
import "@/common/styles/layout.scss";
import "@/common/styles/reset.scss";
import { hyAppInit } from "@/common/app/init";
import { Provider } from "react-redux";
import { nextReduxWrapper } from "@/common/redux";
import "antd/dist/antd.min.css";
import HYFixedMenu from "@/common/components/fixed-menu";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
//import "antd/dist/reset.css";

hyAppInit();

const LaunchPage: React.FC<AppProps> = ({ Component, ...rest }) => {
  const { props } = nextReduxWrapper.useWrappedStore(rest);
  return <Component {...props.pageProps} />;
};

function App(props: AppProps) {
  const router = useRouter();
  // 个人中心系列页面不展示右侧菜单、也不展示快商通
  const showFixedMenu = !router.pathname.startsWith("/user");
  // 开发环境下，屏蔽一些第三方sdk
  let scripts: JSX.Element | null = null;
  if (HYEnv.isProd) {
    scripts = (
      <>
        {/* 友盟 */}
        {/* <Script
          type="text/javascript"
          src="https://s9.cnzz.com/z_stat.php?id=1280549228&web_id=1280549228"
        ></Script> */}
        {/* 快商通 */}
        {showFixedMenu ? (
          <Script src="https://qfck70.kuaishang.cn/bs/ks.j?cI=856046&fI=4052"></Script>
        ) : null}
        {/* 百度统计 */}
        <Script id="baidu-script">
          {`var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?ca1e18820dda1fd297aab852be728e96";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();`}
        </Script>
      </>
    );
  }

  return (
    <ConfigProvider locale={zhCN}>
      <Provider store={appStore}>
        <PersistGate persistor={appStorePersist as any} loading={null}>
          {() => (
            <div
              style={{
                margin: "0 auto",
                maxWidth: 1920,
                minWidth: 1280,
                overflow: "hidden",
                backgroundColor: HYTheme.Color.Bg,
              }}
            >
              {scripts}
              <LaunchPage {...props} />
              {showFixedMenu ? <HYFixedMenu /> : null}
            </div>
          )}
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
