import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

// 官网首页，员工信息
export class TeamPersonModel extends HYNetworkModel {
  [immerable] = true;
  // 图片URL
  avatar: string;
  // 名字
  name: string;
  // 描述
  desc: string;
  // QQ号
  qq: string;
  // 微信号
  wechat: string;

  constructor(data: any) {

    super();
    this.avatar = data?.avatar ?? "";
    this.name = data?.name ?? "";
    this.desc = data?.desc ?? "";
    this.qq = data?.qq ?? "";
    this.wechat = data?.wechat ?? "";
  }
}