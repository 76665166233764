import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

export class FundRecordModel extends HYNetworkModel {

  [immerable] = true;

  id: number;
  // 状态
  status: number;
  // 流水号
  chargeNumber: string;
  // 渠道
  router: number;
  // 金额
  price: number;
  // 金额类型
  priceType: number;

  // 关联Id
  relationId: number;
  // 关联类型
  relationType: number;
  // 关联订单编号
  relationOrder: string;

  // 创建时间
  createdAt: string;

  constructor(data: any) {

    super();
    this.id = data?.id ?? 0;
    this.status = data?.status ?? 0;
    this.chargeNumber = data?.charge_number ?? "";

    this.router = data?.fund_router ?? 0;
    this.price = data?.price ?? 0;
    this.price /= 100;
    this.priceType = data?.price_type ?? 0;

    this.relationId = data?.relation_id ?? 0;
    this.relationType = data?.relation_type ?? 0;
    this.relationOrder = data?.tm_order_no ?? '';

    this.createdAt = data?.created_at ?? "";
  }
}
