import React, { useCallback, useState } from "react";
import styles from "./index.module.scss";
import iContact from "@/assets/common/fixed-menu/contact.png";
import iClient from "@/assets/common/fixed-menu/verify.png";
import iMessage from "@/assets/common/fixed-menu/message.png";
import iTop from "@/assets/common/fixed-menu/top.png";
import { useRouter } from "next/router";
import VerifyServiceModal from "./verify-service";
import KST from "@/common/tools/kst";
import AppRouter from "@/common/app/router";

const HYFixedMenu: React.FC = (props) => {

  const [showVerifyModal, setShowVerifyModal] = useState(false);

  // ----------- Events -----------

  // 点击在线咨询
  const onTapContact = useCallback(() => {
    KST.call();
  }, []);

  // 投诉建议
  const onTapFeedback = useCallback(() => {
    window.open(AppRouter.Others.feedback);
  }, []);

  // 置顶
  const onTapTop = useCallback(() => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  }, []);

  // ----------- UI -----------

  const config = [
    { icon: iContact, title: "在线咨询", onTap: onTapContact },
    { icon: iClient, title: "真假客服", onTap: () => setShowVerifyModal(true) },
    // { icon: iScan, title: "扫一扫" },
    { icon: iMessage, title: "投诉建议", onTap: onTapFeedback },
    { icon: iTop, title: "置顶", onTap: onTapTop },
  ];

  return (
    <>
      <div className={`${styles.container} flex-col`}>
        {config.map((item, index) => {
          return (
            <div key={index} className={`${styles.item} flex-col-center`} onClick={item.onTap}>
              <div className={styles.icon}>
                <img src={item.icon} alt="" />
              </div>
              <h3>{item.title}</h3>
            </div>
          );
        })}
      </div>
      <VerifyServiceModal visible={showVerifyModal} onCancel={() => setShowVerifyModal(false)} />
    </>
  );
};

export default HYFixedMenu;
