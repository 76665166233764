// --------------------------- 错误码定义 ---------------------------
export enum ErrorCode {
  Success = 0,
  // ----- -1xxx 网络层错误 -----
  // 请求出错 - 通用网络原因
  RequestError = -1000,
  // 请求超时
  RequestTimeout = -1001,
  // 网络链接失败
  RequestNetConnect = -1002,

  // ----- New 服务端错误 -----

  // 无效Token
  TokenInvalid = 100000,
  // Token过期
  TokenExpire = 100001,
  // Token验证无效
  TokenSignatureInvalid = 100002,
  DataNotFound = 100003,
  DataExist = 100004,
  // 无权限（前台无用）
  NoAuth = 100005,
  // 商标搜索次数上限
  UseNoLimit = 100006,
  // 异地登录
  OtherLogin = 100007,

  // 商标、订单
  CanNotUpdateTrademarkOrder = 100100,
  PaymentLessThanDeposit = 100101,
  PaymentOverOrderPrice = 100102,
  CustomerBalanceIsNotEnough = 100103,
  RequestFundsOverOrderServer = 100104,
  PayToSellerOverOrderPrice = 100105,
  TrademarkCanNotSale = 100106,

  InvalidParam = 200000,

  // 内部错误
  InternalError = 500000,
}

// 错误码工具
export namespace ErrorCodeTools {
  // --------------- 错误描述映射 ---------------
  const descMap: { [key: number]: string } = {};
  descMap[ErrorCode.RequestTimeout] = "请求超时";
  descMap[ErrorCode.RequestNetConnect] = "无网络连接";
  // Token相关
  descMap[ErrorCode.TokenExpire] = "认证失效";

  // 本地翻译错误码
  export function translateErrorCode(code: ErrorCode): string {
    const msg = descMap[code] ?? "请求失败";
    return `${msg} (${code})`;
  }
}
