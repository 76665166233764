import QueryString from "qs";

function pathCreator(path: string, params: any) {
  if (!params) return path;
  return path + "?" + QueryString.stringify(params);
}

namespace AppRouter {
  // 首页
  export const home = "/";

  /**
   * 用户模块
   */
  export namespace User {
    const prefix = "/user";

    // 登录
    interface IUserLoginParams {
      type?: "signup" | "forget";
      redirect?: string;
    }
    export function login(params?: IUserLoginParams) {
      return pathCreator(prefix + "/login", params);
    }

    /**
     * 个人中心
     */
    export namespace Center {
      const centerPrefix = prefix + "/center";
      // 首页
      export const home = centerPrefix + "/home";
      // 订单
      export const order = centerPrefix + "/order";
      // 资金明细
      export const fund = centerPrefix + "/fund";
      // 个人信息
      export const info = centerPrefix + "/info";
      // 修改密码
      export const updatePassword = centerPrefix + "/update-pwd";
    }
  }

  /**
   * 入驻模块
   */
  export namespace Settled {
    const prefix = "/settled";

    // 入驻业务类型定义
    export type BusinessType = "jd" | "jdhc" | "jdzy" | "jdpop" | "tmall" | "douyin";
    export const businessTypes: BusinessType[] = ["jd", "jdhc", "jdzy", "jdpop", "tmall", "douyin"];

    // 入驻业务介绍
    export function business(type: BusinessType) {
      return prefix + "/" + type;
    }
  }

  /**
   * 代运营模块
   */
  export namespace Agent {
    const prefix = "/agent";
    // 代运营业务介绍页
    export const business = prefix + "-business";
  }

  /**
   * 商标模块
   */
  export namespace TM {
    const prefix = "/tm";

    // 商标业务介绍页
    export const business = prefix + "-business";

    // 商标转让
    interface ITMTransferParams {
      // 商标名称
      tmname?: string;
      // 商标分类
      c?: number | number[];
    }
    export function transfer(params?: ITMTransferParams) {
      return pathCreator(prefix + "-transfer", params);
    }

    // 商标详情
    export function detail(code: string) {
      return prefix + "-detail/" + code;
    }

    // 商标服务介绍
    export function service(type: number) {
      return prefix + "-service/" + type;
    }
    // 国际商标
    export const international = prefix + "-international";
  }

  /**
   * 知识产权
   */
  export namespace Intellectual {
    const prefix = "/intellectual";
    // 专利服务
    export const patent = prefix + "-patent";
    // 软著服务
    export const copyrights = prefix + "-copyrights";
    // 品牌授权
    export const brandAuth = "/brand-auth";
  }

  /**
   * CMS模块
   */
  export namespace CMS {
    const prefix = "/cms";

    // 文章列表
    interface ICMSListProps {
      page?: number;
      classify?: number;
    }
    export function list(params?: ICMSListProps) {
      return pathCreator(
        prefix + "/list",
        params ? { p: params.page, c: params.classify } : undefined
      );
    }

    // 文章详情
    export function detail(id: number) {
      return prefix + "/detail/" + id;
    }
  }

  /**
   * 其他信息
   */
  export namespace Others {
    // 关于我们
    export const about = "/about";
    // 隐私保护
    export const privacy = "/paper/privacy";
    // 免责声明
    export const disclaimer = "/paper/disclaimer";
    // 人才招聘
    export const recruit = "/paper/recruit";
    // 商务合作
    export const cooperate = "/paper/cooperate";
    // 营业执照
    export const lincense = "/others/business-license";
    // 投诉建议
    export const feedback = "/others/feedback";
  }
}

export default AppRouter;
