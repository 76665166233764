import { IHYStorage } from "@hy-sdk/framework";

export const hyStorageInstance: IHYStorage = {
  saveSync: function (key: string, value: any): void {
    const strValue = JSON.stringify(value);
    localStorage.setItem(key, strValue);
  },
  saveAsync: function (key: string, value: any): void {
    const strValue = JSON.stringify(value);
    localStorage.setItem(key, strValue);
  },
  loadSync: function <T = any>(key: string): T | undefined {
    const value = localStorage.getItem(key);
    if (!value) return undefined;
    return JSON.parse(value);
  },
  loadAsync: async function <T = any>(
    key: string,
    success?: ((value: T) => void) | undefined,
    failed?: ((error: string) => void) | undefined
  ): Promise<T | undefined> {
    const strValue = localStorage.getItem(key);
    let value: T | undefined = undefined;
    if (strValue) value = JSON.parse(strValue);
    if (value) success?.(value);
    return value;
  },
  removeSync: function (key: string): void {
    localStorage.removeItem(key);
  },
  removeAsync: function (
    key: string,
    success?: (() => void) | undefined,
    failed?: ((error: string) => void) | undefined
  ): Promise<void> {
    localStorage.removeItem(key);
    success?.();
    return new Promise((resolve) => {
      resolve();
    });
  },
};
