import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

export default class CheckIfRealModel extends HYNetworkModel {
  
  [immerable] = true;

  isReal: boolean;
  name: string;
  number: string;
  image: string;
  describe: string;

  constructor(data:any) {
    super();
    this.isReal = data.is_real ?? false;
    this.name = data?.name ?? '';
    this.number = data?.number ?? '';
    this.image = data?.image ?? '';
    this.describe = data?.describe ?? '';
  }
}