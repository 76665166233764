import { ListPageModel } from "@/common/defines/list-model";
import { hyNetworkBusiness } from "@/common/network/business";
import { HYNetworkManager, HYNetworkRequest } from "@hy-sdk/framework";
import { TMOrderModel } from "./order/order-model";
import { ITMOrderListParams } from "./order/params";
import { ITMSearchParams } from "./tm/params";
import TMModel from "./tm/tm-model";
import TMURL from "./url";

const businessId = hyNetworkBusiness.id;

namespace TMApi {
  /**
   * 获取首页展示商标
   */
  export function homeList(type: 0 | 1) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: TMURL.homeList(type),
        converter: (data) => new ListPageModel(data, TMModel),
      })
    ).response;
  }

  /**
   * 商标搜索
   */
  export function search(params: ITMSearchParams) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: TMURL.search,
        data: params,
        converter: (data) => new ListPageModel(data, TMModel),
      })
    ).response;
  }

  /**
   * 获取精品推荐商标
   */
  export function recommend() {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: TMURL.recommend,
        converter: (data) => new ListPageModel(data, TMModel),
      })
    ).response;
  }

  /**
   * 获取商标详情
   */
  export function detail(code: string) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: TMURL.detail(code),
        converter: (data) => new TMModel(data),
      })
    ).response;
  }

  /**
   * 获取同名商标
   */
  export function sameName(name: string) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: TMURL.sameName(name),
        converter: (data) => new ListPageModel(data, TMModel),
      })
    ).response;
  }

  /**
   * 订单
   */
  export namespace Order {
    /**
     * 获取列表
     */
    export function list(params: ITMOrderListParams) {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "POST",
          path: TMURL.Order.list,
          data: params,
          converter: (data) => new ListPageModel(data, TMOrderModel),
        })
      ).response;
    }

    /**
     * 创建订单
     */
    export function create(code: string) {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "POST",
          path: TMURL.Order.create,
          data: { trademark_item_code: code },
        })
      ).response;
    }

    /**
     * 取消订单
     */
    export function cancel(orderNo: string) {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "POST",
          path: TMURL.Order.cancel,
          data: { order_no: orderNo },
        })
      ).response;
    }
  }
}

export default TMApi;
