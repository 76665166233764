import { message, Modal, Spin } from "antd";
import React, { useCallback, useState } from "react";
import styles from "./index.module.scss";

import iSearch from "@/assets/common/fixed-menu/verify-modal/search.png";
import iWarning from "@/assets/common/fixed-menu/verify-modal/warning.png";
import iSafe from "@/assets/common/fixed-menu/verify-modal/safe.png";
import { SearchOutlined } from "@ant-design/icons";
import iLogo from "../../../../public/logo192.png";
import { MobileValidator, QQValidator, WeChatValidator } from "@/common/tools/validator";
import CheckIfRealModel from "@/modules/others/api/models/check-if-real-model";
import ModuleOthers from "@/modules/others";

export interface IVerifyServiceModalProps {
  visible: boolean;
  onCancel: () => void;
}

type Result = CheckIfRealModel & { value: string };

const VerifyServiceModal: React.FC<IVerifyServiceModalProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [result, setResult] = useState<Result>();

  // ----------------- Events & Request -----------------

  // 取消Modal
  const onCancel = useCallback(() => {
    setLoading(false);
    setValue("");
    setResult(undefined);
    props.onCancel();
  }, [props]);

  // 请求
  const request = useCallback(async (value: string) => {
    if (
      MobileValidator.validate(value).result === false &&
      QQValidator.validate(value).result === false &&
      WeChatValidator.validate(value).result === false
    ) {
      message.warning("请输入正确的手机号、QQ号、微信号");
      return;
    }

    setLoading(true);
    let response = await ModuleOthers.Api.checkIfReal(value);
    setLoading(false);
    if (!response.success) {
      message.error(response.errorDescribe);
      return;
    }

    (response.model as Result).value = value;
    setResult(response.model as any);
  }, []);

  // ----------------- UI -----------------

  // 头部内容
  let head = (
    <div
      className={`${styles.head} flex-row flex-main-axis-between flex-cross-axis-center`}
    >
      <div className="flex-col">
        <div className={styles.title}>真假客服验证</div>
        <div className={styles.desc}>
          为防止不法分子冒充平台客服，您可以在这里验证其联系方式是否为真
        </div>
      </div>
      <img src={iSearch} alt="" />
    </div>
  );

  // 搜索栏
  let searchBar = (
    <div className="flex-row-center">
      <input
        className="flex1"
        type="text"
        placeholder="请输入您要验证客服的联系方式：手机、QQ、微信"
        onChange={(e) => setValue(e.target.value)}
        maxLength={20}
      />
      <div
        className={`${styles.button} flex-row-center`}
        onClick={() => request(value)}
      >
        <SearchOutlined className={styles.icon} />
        <span>立即验证</span>
      </div>
    </div>
  );

  // 结果内容
  const createResultUI = useCallback((result: Result) => {
    
    const avatar = result.image.length > 0 ? result.image : iLogo;
    return (
      <div className={`${styles.resultContent} flex-row flex-cross-axis-center`}>
        <img
          className={styles.resultIcon}
          src={result.isReal ? iSafe : iWarning}
          alt=""
        />
        <div className="flex-col flex-main-axis-center">
          <div className={styles.resultTitle}>
            {`您当前查询的 ${result.value} 是`}
            {result.isReal ? (
              <span className={styles.real}>真客服</span>
            ) : (
              <span className={styles.fake}>假客服</span>
            )}
          </div>
          {result.isReal ? (
            <div className={`${styles.person} flex-row flex-cross-axis-center`}>
              <img className={styles.avatar} src={avatar} alt="" />
              <div style={{ marginLeft: 20 }} className="flex-col">
                <div className={styles.name}>
                  {`${result.name} - 工号${result.number}`}
                </div>
                <div className={styles.desc}>{result.describe}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }, []);

  let content = (
    <div className={`${styles.content} flex-col`}>
      {searchBar}
      {result !== undefined ? createResultUI(result) : null}
    </div>
  );

  // 底部提示
  let footer = (
    <div className={styles.footer}>
      提示:
      查询结果若为真客服，仍不排除非法人员有通过技术手段伪造号码进行诈骗的可能，如有疑问，请联系我们官方电话
      17760536361
    </div>
  );

  return (
    <Spin spinning={loading}>
      <Modal
        width={700}
        bodyStyle={{ padding: 0 }}
        centered={true}
        destroyOnClose={true}
        closable={false}
        open={props.visible}
        onCancel={onCancel}
        footer={null}
      >
        <div className={`${styles.container} flex-col`}>
          {head}
          {content}
          <hr />
          {footer}
        </div>
      </Modal>
    </Spin>
  );
};

export default VerifyServiceModal;
