import reducer from "./reducer";
import { createWrapper } from "next-redux-wrapper";
import { persistStore } from "redux-persist";
import { useDispatch, useSelector } from "react-redux";
import { HYEnv } from "@hy-sdk/framework";
import { configureStore } from "@reduxjs/toolkit";
import { ReduxUserAction, ReduxUserActionType } from "@/modules/user/redux/action";
import ReduxCommonStorageAction, { ReduxCommonStorageActionType } from "./common-storage/action";
import { useMemo } from "react";

const isServer = typeof window === "undefined";

// ----------------------------- Actions --------------------------------

type Actions = ReduxCommonStorageActionType | ReduxUserActionType;
export const AppActions = {
  Common: ReduxCommonStorageAction,
  User: ReduxUserAction,
};

// ----------------------------- Store & State --------------------------------
export const appStore = configureStore({
  reducer: reducer,
  devTools: HYEnv.isDev,
  middleware: (getDefaultMiddleware) => {
    const result = getDefaultMiddleware({
      serializableCheck: false,
    });
    return result;
  },
});
export const appStorePersist = isServer ? undefined : persistStore(appStore);
// 全局State类型
export type AppState = ReturnType<typeof appStore.getState>;
// NextJS包裹
export const nextReduxWrapper = createWrapper(() => appStore, {
  debug: HYEnv.isDev,
});

// ----------------------------- API & Hooks --------------------------------

// 基于AppState的子级state获取
export function useAppSelector<TSelected = unknown>(
  selector: (state: AppState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  const state = useSelector<AppState, TSelected>((s) => {
    return selector(s);
  }, equalityFn);
  return state;
}

export function useAppDispatch() {
  const dispath = useDispatch();
  const func = useMemo(() => {
    return (action: Actions) => {
      dispath(action);
    }
  }, [dispath])
  return func;
}

export function appDispatch(action: Actions) {
  appStore.dispatch(action);
}
