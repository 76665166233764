import HYProjectConfig from "@/project-config";
import {
  HYNetworkBusiness,
  HYEnv,
} from "@hy-sdk/framework";
import { hyNetworkInterceptor } from "./interceptor";
import { hyNetworkParser } from "./parser";

// 汇蚁小程序，后台业务
export const hyNetworkBusiness = new HYNetworkBusiness({
  id: "hynet",
  baseURL: HYEnv.isTest
    ? HYProjectConfig.Network.baseURLTest
    : HYProjectConfig.Network.baseURL,
  parser: hyNetworkParser,
  interceptor: hyNetworkInterceptor,
  defaultTimeout: 10 * 1000,
  retry: {
    maxLimit: 2,
    interval: 200,
  }
});
