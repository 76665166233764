import IValidatorResult from "./defines";


// 手机号
export class MobileValidator {

  static validate (value?: string) : IValidatorResult {
    if (value === undefined) return {result: false, helpText: '手机号不能为空'};
    const reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;

    if (reg.test(value)) {
      return {result: true};
    }
    else {
      return {result: false, helpText: '请输入正确的手机号'};
    }
  }
}

// 密码
export class PasswordValidator {

  // 6-20位
  // a-z A-Z 0-9
  static validate (value?: string) : IValidatorResult {

    if (value === undefined) return {result: false, helpText:'密码不能为空'};
    if (value.length < 6 || value.length > 20) {
      return {result: false, helpText:'密码长度必须为6-20位'};
    }
    const reg = /^[a-zA-Z0-9]+$/;
    if (reg.test(value)) {
      return {result: true};
    }
    else {
      return {result: false, helpText:'密码只能为数字或者字母的组合'}
    }
  }
}

// QQ号
export class QQValidator {

  static validate (value?: string, notNull?: boolean) : IValidatorResult {

    if (value === undefined) {
      if (notNull === true) {
        return {result: false, helpText: 'QQ号不能为空'};
      }
      return {result: true};
    }

    if (value.length < 6 || value.length > 12) {
      return {result: false, helpText: '请输入正确的QQ号'};
    }
    
    const reg = /^[1-9]\d+$/;
    if (reg.test(value)) {
      return {result: true};
    }
    else {
      return {result: false, helpText: '请输入正确的QQ号'};
    }
  }
}

// QQ号
export class WeChatValidator {

  static validate (value?: string, notNull?: boolean) : IValidatorResult {

    if (value === undefined) {
      if (notNull === true) {
        return {result: false, helpText: '微信号不能为空'};
      }
      return {result: true};
    }

    if (value.length < 6 || value.length > 20) {
      return {result: false, helpText: '请输入正确的微信号'};
    }
    
    const reg = /^[0-9a-zA-Z_-]*$/;
    if (reg.test(value)) {
      return {result: true};
    }
    else {
      return {result: false, helpText: '请输入正确的微信号'};
    }
  }
}

// 验证码
export class CaptchaValidator {

  static validate ({input, dest}: {input: string, dest: string}) : IValidatorResult {

    if (input.length === 0) return {result: false, helpText: '验证码不能为空'};
    if (input === dest) {
      return {result: true};
    }
    else {
      return {result: false, helpText: '请输入正确的验证码'};
    }
  }
}