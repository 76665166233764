import { CMSArticleModel } from "./article-model";

// 首页，一列分类数据
export default class CMSArticleHomeListModel {

  classifyId: number;
  classifyName: string;
  classifyDesc: string;
  list: CMSArticleModel[];

  constructor(data:any) {

    this.classifyId = data?.classify_id ?? 0;
    this.classifyName = data?.classify_name ?? '';
    this.classifyDesc = data?.classify_describe ?? '';
    this.list = [];
    for (const obj of data?.list ?? []) {
      this.list.push(new CMSArticleModel(obj));
    }
  }
}