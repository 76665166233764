import {
  HYNetworkInterceptor,
  HYNetworkRequest,
  HYEnv,
  HYNetworkResponse,
  HYLoger,
} from "@hy-sdk/framework";
import AppRouter from "../app/router";
import { AppActions, appDispatch, appStore } from "../redux";
import { ErrorCode } from "./error";

const logger = HYLoger.createLogger("Api");
const isServer = typeof window === "undefined";

/**
 * 网络通信拦截层
 *
 * 1，负责请求前，公共请求数据的注入
 * 2，负责请求后，特殊情况拦截处理
 */
class HYInterceptor implements HYNetworkInterceptor {
  /**
   * 请求前
   */
  onRequest(request: HYNetworkRequest): void {
    // 请求头注入
    this.requestCommonHeaderInject(request);

    // 开发模式打印
    if (!isServer && HYEnv.isDev) {
      if (request.data?.constructor === FormData) {
        logger.info({
          type: "Request",
          api: `${request.method} ${request.path}`,
          traceId: request.header?.trace_id,
          header: request.header,
          data: this.convertFormDataToPrint(request.data),
        });
      } else {
        logger.info({
          type: "Request",
          api: `${request.method} ${request.path}`,
          traceId: request.header?.trace_id,
          header: request.header,
          data: request.data,
        });
      }
    }
  }

  /**
   * 服务端返回
   */
  onResponse(request: HYNetworkRequest, response: HYNetworkResponse): void {
    if (HYEnv.isDev) {
      const printValue: { [key: string]: any } = {
        type: `Response ${response.error ? "failed" : "success"}`,
        api: `${request.method} ${request.path}`,
        traceId: request.header?.trace_id,
      };
      if (response.error) printValue.error = response.error;
      if (response.model) printValue.model = response.model;
      if (response.data) printValue.data = response.data;
      // SSR侧不打印成功信息
      if (!isServer && response.success) {
        logger.info(printValue);
      } else if (!response.success) {
        logger.warn(printValue);
      }
    }
  }

  /**
   * 重试咨询
   */
  needRetry(_request: HYNetworkRequest, _response: HYNetworkResponse): boolean {
    return false;
  }

  /**
   * 完成拦截
   */
  interceptComplete(_request: HYNetworkRequest, response: HYNetworkResponse): boolean {
    // Token失效处理
    let code = response.error?.code;
    if (code && [ErrorCode.TokenInvalid, ErrorCode.TokenExpire, ErrorCode.TokenSignatureInvalid].includes(code)) {
      appDispatch(AppActions.User.logout());
      window.location.href = window.location.origin + AppRouter.User.login();
    }
    return false;
  }

  /**
   * 重试咨询
   */
  allowRetry(_request: HYNetworkRequest, _response: HYNetworkResponse): boolean {
    return true;
  }

  /**
   * 读取缓存
   */
  onLoadCache(_request: HYNetworkRequest, _cache: any) {}

  /**
   * 保存缓存
   */
  onSaveCache(_request: HYNetworkRequest, _cache: any) {}

  /**
   * 请求公共Header注入
   */
  private requestCommonHeaderInject(request: HYNetworkRequest) {
    if (!request.header) request.header = {};
    request.header["content-type"] = "application/json";
    // Token注入
    const token = appStore.getState().user.token;
    if (token) {
      request.header["token"] = token;
    }
  }

  /**
   * 转换formData为打印对象
   */
  private convertFormDataToPrint(data: FormData) {
    const keys = Array.from(data.keys()).filter((k) => k !== "file");
    const obj: { [key: string]: string } = {
      file: "file object",
    };
    for (const key of keys) {
      obj[key] = data.get(key) as string;
    }
    return obj;
  }
}

export const hyNetworkInterceptor = new HYInterceptor();
