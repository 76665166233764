import { Reducer } from "redux";
import { UserModel } from "../api/models/login-model";
import { ReduxUserActionType } from "./action";

export interface IReduxUserState {
  lastMobile?: string;
  token?: string;
  user?: UserModel;
}

const initState: IReduxUserState = {};
export const reduxUserReducer: Reducer<IReduxUserState, ReduxUserActionType> = (
  preState = initState,
  action: ReduxUserActionType
) => {
  switch (action.type) {
    case "user.login":
      return {
        ...preState,
        lastMobile: action.lastMobile,
        token: action.token,
        user: action.user,
      };
    case "user.logout": {
      return { ...initState, lastMobile: preState.lastMobile };
    }
    default:
      return preState;
  }
};
