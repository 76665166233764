
import TMDefines, { TMRegisterType } from "../../defines/tm-defines";
import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

// 子分类
export class TMSubClassifyModel {

  [immerable] = true;
  code: string;
  name: string;

  constructor(data: any) {
    this.code = data?.code ?? "";
    this.name = data?.name ?? "";
  }
}

// 扩展信息 - 标库同步信息
export class TMExtendModel {

  [immerable] = true;

  // 商标图 - 标库地址
  photo: string;

  // 申请人-中文
  applicantCN: string;
  // 申请人-英文
  applicantEN: string;
  // 申请时间
  applyDate: string;

  // 初审公告期号
  announcementIssue: string;
  // 初审公告日期
  announcementDate: string;

  // 注册公告期号
  registerIssue: string;
  // 注册公告日期
  registerDate: string;

  // 专用权期限 - 开始
  privateStartDate: string;
  // 专用权期限 - 结束
  privateEndDate: string;

  constructor(data: any) {
    this.photo = data?.img ?? "";
    this.applicantCN = data?.applicant_cn ?? "";
    this.applicantEN = data?.applicant_en ?? "";
    this.applyDate = data?.apply_date ?? "";

    this.announcementIssue = data?.announcement_issue ?? "";
    this.announcementDate = data?.announcement_date ?? "";

    this.registerIssue = data?.register_issue ?? "";
    this.registerDate = data?.register_date ?? "";

    this.privateStartDate = data?.private_start_date ?? "";
    this.privateEndDate = data?.private_end_date ?? "";
  }
}

// 推广人信息
export class TMPersonModel {

  [immerable] = true;

  id: number;
  avatar: string;
  name: string;
  mobile: string;
  wechat: string;
  wechatQRCode: string;
  qq: string;

  constructor(data:any) {
    this.id = data.id ?? 0;
    this.avatar = data.avatar ?? '';
    this.name = data.name ??'';
    this.mobile = data.mobile ?? '';
    this.wechat = data.wechat_number ?? '';
    this.wechatQRCode = data.wechat_qr_code ?? '';
    this.qq = data.qq_number ?? '';
  }
}

// 商标Model
export default class TMModel extends HYNetworkModel {

  [immerable] = true;

  key: string;
  // 多类别个数
  duplicateCount: number;
  // 主分类
  classify: number;
  // 子分类
  subClassify: TMSubClassifyModel[];
  // 商标名
  name: string;
  // 商品编号
  code: string;
  // 注册类型
  type: TMRegisterType;
  // 商标图，我们自己OSS的地址
  photo: string;
  // 商标渲染图
  renderPhoto: string;
  // 售价（元）
  price: number;
  // 成本价 - 内部员工请求(staff-search)时才有这个数据
  costPrice?: number;
  // 扩展信息
  extend: TMExtendModel;
  // 推广人
  spreadPerson?: TMPersonModel;

  // ----------- UI相关 -----------

  // UI字段 - 名字
  private _uiName?: string;
  // UI字段 - 分类
  private _uiCategory?: string;
  // UI字段 - 使用范围
  private _uiScope?: string;
  // UI字段 - 价格
  private _uiPrice?: string;

  constructor(data: any) {

    super();
    this.duplicateCount = data.duplicate_count ?? 0;
    this.classify = data.classify ?? 0;
    this.subClassify = [];
    for (const obj of data.extend_goods ?? []) {
      this.subClassify.push(new TMSubClassifyModel(obj));
    }

    this.name = data.name ?? "";
    this.code = data.item_code ?? "";
    this.key = this.code;
    this.type = data.type ?? 0;
    this.photo = data.photo_url ?? "";
    this.renderPhoto = data.render_photo_url ?? '';
    this.price = data.sale_price ?? 0;
    this.price /= 100;
    this.costPrice = data.cost_price;
    if (this.costPrice !== undefined) {
      this.costPrice /= 100;
    }
    this.extend = new TMExtendModel(data.extend_info);

    if (data.spread_info) {
      this.spreadPerson = new TMPersonModel(data.spread_info);
    }
  }

  // UI - 名字
  get uiName(): string {
    if (this._uiName) return this._uiName;
    let categoryName = this.classify >= 10 ? `${this.classify}类` : `0${this.classify}类`;
    this._uiName = categoryName + '-' + this.name;
    return this._uiName;
  }

  // UI - 分类
  get uiCategory(): string {
    if (this._uiCategory) return this._uiCategory;
    let categoryName = this.classify >= 10 ? `${this.classify}类` : `0${this.classify}类`;
    this._uiCategory = categoryName + '-' + TMDefines.Categories[this.classify - 1];
    return this._uiCategory;
  }

  // UI - 获取使用范围文案
  get uiScope(): string {
    if (this._uiScope) return this._uiScope;
    this._uiScope = this.subClassify.reduce<string>(
      (previousValue, currentValue, index) => {
        if (index === 0) {
          return previousValue + currentValue.name;
        } else {
          return previousValue + "," + currentValue.name;
        }
      },
      "使用范围："
    );
    return this._uiScope;
  }

  // UI - 获取价格文案
  get uiPrice(): string {
    if (this._uiPrice) return this._uiPrice;
    // 高于一万
    if (this.price >= 10000) {
      this._uiPrice = `￥ ${(this.price / 10000).toFixed(2)}万`;
    } else {
      this._uiPrice = `￥ ${this.price}`;
    }
    return this._uiPrice;
  }
}
