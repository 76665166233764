namespace UserURL {
  const prefix = "/frontend/user";

  // POST 登录
  export const login = prefix + "/login";
  // POST 登出
  export const logout = prefix + "/logout";
  // POST 注册
  export const signUp = prefix + "/sign-up";
  // POST 获取验证码
  export const sendCaptcha = prefix + "/send-captcha";
  // POST 忘记密码
  export const forgetPassword = prefix + "/change-password-direct";
  // POST 修改密码
  export const updatePassword = prefix + "/change-password";
  // GET 个人中心，首页统计
  export const statistics = prefix + "/statistics";
  // POST 获取资金变动记录
  export const fundRecord = prefix + "/amount-record";
  // GET 获取支付信息
  export const paymentInfo = prefix + "/payment-info";
  // POST 订单支付 - 支付宝
  export const paymentAlipay = prefix + "/send-alipay";
}

export default UserURL;
