import { HYNetworkModel } from "@hy-sdk/framework";
import { immerable } from "immer";

function createInstance<T>(create: new (value: any) => T, value: any): T {
  return new create(value);
}

export class ListModel<T> extends HYNetworkModel {

  [immerable] = true;
  list: T[];

  constructor(data: any, create: new (value: any) => T, dataIsArray?: boolean) {
    super();
    this.list = [];

    if (dataIsArray) {
      for (const obj of data ?? []) {
        this.list.push(createInstance<T>(create, obj));
      }
    } else {
      for (const obj of data?.list ?? []) {
        this.list.push(createInstance<T>(create, obj));
      }
    }
  }
}

export interface IListData<T> {
  list: T[];
  page: number;
  pageSize?: number;
  total: number;
}

export class ListPageModel<T> extends ListModel<T> implements IListData<T> {
  page: number;
  pageSize: number;
  total: number;

  constructor(data: any, create: new (value: any) => T) {
    super(data, create);
    this.page = data?.page?.current_page ?? 0;
    this.pageSize = data?.page?.page_size ?? 0;
    this.total = data?.page?.total ?? 0;
  }
}
