// 订单状态
export enum TMOrderStatus {
  // 0 删除
  Delete,
  // 1 待付款
  PendingPayment,
  // 2 取消
  Cancel,
  // 3 付了部分钱，但是取消了
  CancelWithFines,
  // 4 已付订金
  PayDeposit,
  // 5 已付全款
  PayAll,
  // 6 交易完成
  Complete,
  // 7 已结算
  Settlement,
  // 8 已全部退款
  CompleteRefund,
}

export default class TMOrderDefines {
  // 获取状态翻译
  static readonly GetStatusValue = (status: TMOrderStatus): string => {
    switch (status) {
      case TMOrderStatus.PendingPayment:
        return "待付款";
      case TMOrderStatus.Cancel:
      case TMOrderStatus.CancelWithFines:
        return "已取消";
      case TMOrderStatus.PayDeposit:
        return "已付订金";
      case TMOrderStatus.PayAll:
        return "已付全款";
      case TMOrderStatus.Complete:
      case TMOrderStatus.Settlement:
        return "交易完成";
      case TMOrderStatus.CompleteRefund:
        return "已退款";
      default: return "-";
    }
  };

  // 获取状态选项 - 搜索用
  static readonly GetStatusSearchOptions = [
    { id: TMOrderStatus.PendingPayment, value: "待付款" },
    { id: TMOrderStatus.PayDeposit, value: "已付订金" },
    { id: TMOrderStatus.PayAll, value: "已付全款" },
    { id: TMOrderStatus.Complete, value: "已完成" },
    { id: TMOrderStatus.Settlement, value: "已结算" },
  ];
}
