import { Property } from "csstype";
import style from "./theme.module.scss";

type Color = Property.Color;

/**
 * 直接使用scss export的变量，没有代码提示，容易出错
 * 因此这里维护一份定义
 */
const HYTheme = {
  Color: {
    Primary: style.colorPrimary as Color,
    PrimaryEnd: style.colorPrimaryEnd as Color,
    Bg: style.colorBg as Color,
    Text1: style.colorText1 as Color,
    Text2: style.colorText2 as Color,
    Text3: style.colorText3 as Color,
  }
};

export default HYTheme;
