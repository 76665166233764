namespace CMSURL {
  const prefix = "/frontend/article";

  // POST 获取文章首页列表
  export const homeList = prefix + "/home-page-list";
  // POST 获取文章列表
  export const list = prefix + "/list";
  // GET 获取文章详情
  export function detail(id: string) {
    return prefix + "/detail/" + id;
  }
  // GET 获取热门文章
  export const hotList = prefix + "/hot-list";
  // GET 获取相关文章
  export function relativeList(id: number) {
    return prefix + "/relate-list/" + id;
  }
  // GET 获取文章分类列表
  export const allClassify = prefix + "/all-classify";
}

export default CMSURL;
