import { HYNetworkModel } from "@hy-sdk/framework";
import { CMSArticleStatus } from "../../defines";
import { immerable } from "immer";

// 文章前一页、后一页链接
export class CMSArticleLinkModel {

  [immerable] = true;

  id: number;
  title: string;
  brief: string;

  constructor(data:any) {
    this.id = data?.id ?? 0;
    this.title = data?.title ?? '';
    this.brief = data?.brief ?? '';
  }
}

// 文章标签
export class CMSArticleLabelModel {

  [immerable] = true;

  id: number;
  name: string;

  constructor(data:any) {
    this.id = data?.id ?? 0;
    this.name = data?.name ?? '';
  }
}

// 文章
export class CMSArticleModel extends HYNetworkModel {

  [immerable] = true;

  // 文章Id
  id: number;
  // 所属分类Id
  classifyId: number;
  // 所属分类名字
  classifyName: string;
  // 文章标签组
  label: CMSArticleLabelModel[];
  // 阅读数
  readNum: number;
  // 来源
  source: string;
  // 状态
  status: CMSArticleStatus;
  // 作者
  author: string;
  // 简介
  brief: string;
  // 标题
  title: string;
  // 内容
  content: string;
  // 封面图
  coverImage: string;
  // 创建时间
  createdAt: string;
  // 更新时间
  updatedAt: string;
  // 上一篇
  previous?: CMSArticleLinkModel;
  // 下一篇
  next?: CMSArticleLinkModel;

  constructor(data:any) {

    super();
    this.id = data?.id ?? 0;
    this.classifyId = data?.classify_id ?? 0;
    this.classifyName = data?.classify_name ?? '';
    this.label = [];
    for (const obj of data?.label ?? []) {
      this.label.push(new CMSArticleLabelModel(obj));
    }

    this.readNum = data?.read_num ?? 0;
    this.source = data?.source ?? '';
    this.status = data?.status ?? 0;
    this.author = data?.author ?? '';
    this.brief = data?.brief ?? '';
    this.title = data?.title ?? '';
    this.content = data?.content ?? '';
    this.coverImage = data?.cover_image ?? '';
    this.createdAt = data?.created_at ?? '';
    this.updatedAt = data?.updated_at ?? '';

    if (data?.previous) {
      this.previous = new CMSArticleLinkModel(data.previous);
    }
    if (data?.next) {
      this.next = new CMSArticleLinkModel(data.next);
    }
  }
}
