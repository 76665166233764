import { Action } from "redux";
import { UserModel } from "../api/models/login-model";

interface ILoginAction extends Action {
  type: "user.login";
  lastMobile: string;
  token: string;
  user: UserModel;
}

interface ILogoutAction extends Action {
  type: "user.logout";
}

export type ReduxUserActionType = ILoginAction | ILogoutAction;
export namespace ReduxUserAction {
  /** 登录成功 */
  export function login(params: Omit<ILoginAction, "type">): ILoginAction {
    return { type: "user.login", ...params };
  }

  /** 登出 */
  export function logout(): ILogoutAction {
    return { type: "user.logout" };
  }
}
