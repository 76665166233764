
/**
 * 项目相关全局配置属性
 */
namespace HYProjectConfig {

  /** 域名地址 */
  export const Host = "https://www.huiyitop.com";

  /** 网络通信 */
  export namespace Network {
    /** API基地址 */
    export const baseURL = "https://new-api.huiyitop.com/api";
    /** API基地址 测试环境 */
    export const baseURLTest = "https://new-api-test.huiyitop.com/api";
  }
}

export default HYProjectConfig;