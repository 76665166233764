import { Action } from "redux";

interface ISetAction extends Action {
  type: "common-storage.set";
  key: string;
  value: any;
}

interface IDeleteAction extends Action {
  type: "common-storage.delete";
  key: string;
}

interface IClearAction extends Action {
  type: "common-storage.clear";
}

export type ReduxCommonStorageActionType = ISetAction | IDeleteAction | IClearAction;
export default class ReduxCommonStorageAction {
  set(key: string, value: any): ISetAction {
    return {
      type: "common-storage.set",
      key,
      value,
    };
  }

  delete(key: string): IDeleteAction {
    return {
      type: "common-storage.delete",
      key,
    };
  }

  clear(): IClearAction {
    return { type: "common-storage.clear" };
  }
}
