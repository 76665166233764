import { ListModel, ListPageModel } from "@/common/defines/list-model";
import { hyNetworkBusiness } from "@/common/network/business";
import { HYNetworkManager, HYNetworkRequest } from "@hy-sdk/framework";
import { CMSArticleClassifyModel } from "./models/article-classify-model";
import CMSArticleHomeListModel from "./models/article-home-list-model";
import { CMSArticleModel } from "./models/article-model";
import { ICMSArticleHomeListParams, ICMSArticleListParams } from "./models/params";
import CMSURL from "./url";

const businessId = hyNetworkBusiness.id;

namespace CMSApi {
  /**
   * 文章
   */
  export namespace Article {
    /**
     * 获取首页列表
     */
    export function homeList(params: ICMSArticleHomeListParams) {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "POST",
          path: CMSURL.homeList,
          data: params,
          converter: (data) => new ListModel(data, CMSArticleHomeListModel, true),
        })
      ).response;
    }

    /**
     * 获取分类
     */
    export function getClassify() {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "GET",
          path: CMSURL.allClassify,
          converter: (data) => new ListModel(data, CMSArticleClassifyModel),
        })
      ).response;
    }

    /**
     * 获取分类列表
     */
    export function list(params: ICMSArticleListParams) {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "POST",
          path: CMSURL.list,
          data: params,
          converter: (data) => new ListPageModel(data, CMSArticleModel),
        })
      ).response;
    }

    /**
     * 获取详情
     */
    export function detail(id: string) {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "GET",
          path: CMSURL.detail(id),
          converter: (data) => new CMSArticleModel(data),
        })
      ).response;
    }

    /**
     * 获取热门文章
     */
    export function hotList(size?: number) {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "GET",
          path: CMSURL.hotList,
          data: { page_size: size },
          converter: (data) => new ListModel(data, CMSArticleModel),
        })
      ).response;
    }

    /**
     * 获取相关文章
     */
    export function relativeList(classifyId: number) {
      return HYNetworkManager.sendRequest(
        new HYNetworkRequest({
          businessId,
          method: "GET",
          path: CMSURL.relativeList(classifyId),
          converter: (data) => new ListPageModel(data, CMSArticleModel),
        })
      ).response;
    }
  }
}

export default CMSApi;
