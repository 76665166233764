import { hyNetworkBusiness } from "@/common/network/business";
import { HYNetworkManager, HYNetworkRequest } from "@hy-sdk/framework";
import { LoginModel } from "./models/login-model";
import {
  IForgetPasswordParams,
  IFundRecordParams,
  ILoginParams,
  IPaymentAlipayParam,
  ISignUpParams,
} from "./models/params";
import UserURL from "./url";
import MD5 from "crypto-js/md5";
import OrderStatisticsModel from "./models/order-statistics-model";
import { ListPageModel } from "@/common/defines/list-model";
import { FundRecordModel } from "./models/fund-record-model";
import { PaymentInfoModel } from "./models/payment-info-model";
import { PaymentAlipayModel } from "./models/payment-alipay-model";

const businessId = hyNetworkBusiness.id;

namespace UserApi {
  /**
   * 登录
   */
  export function login(params: ILoginParams) {
    if (params.password) params.password = MD5(params.password).toString();
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: UserURL.login,
        data: params,
        converter: (data) => new LoginModel(data),
      })
    ).response;
  }

  /**
   * 登出
   */
  export function logout() {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: UserURL.logout,
      })
    ).response;
  }

  /**
   * 注册
   */
  export function signUp(params: ISignUpParams) {
    params.password = MD5(params.password).toString();
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: UserURL.signUp,
        data: params,
        converter: (data) => new LoginModel(data),
      })
    ).response;
  }

  /**
   * 获取验证码
   */
  export function sendCaptcha(mobile: string) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: UserURL.sendCaptcha,
        data: { mobile },
      })
    ).response;
  }

  /**
   * 忘记密码
   */
  export function forgetPassword(params: IForgetPasswordParams) {
    if (params.new_password) params.new_password = MD5(params.new_password).toString();
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: UserURL.forgetPassword,
        data: params,
      })
    ).response;
  }

  /**
   * 修改密码
   */
  export function updatePassword(oldPassword: string, newPassword: string) {
    oldPassword = MD5(oldPassword).toString();
    newPassword = MD5(newPassword).toString();
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: UserURL.updatePassword,
        data: {
          old_password: oldPassword,
          new_password: newPassword,
        },
      })
    ).response;
  }

  /**
   * 订单统计信息
   */
  export function orderStatistics() {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: UserURL.statistics,
        converter: (data) => new OrderStatisticsModel(data),
      })
    ).response;
  }

  /**
   * 资金变动记录
   */
  export function fundRecords(params: IFundRecordParams) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "POST",
        path: UserURL.fundRecord,
        data: params,
        converter: (data) => new ListPageModel(data, FundRecordModel),
      })
    ).response;
  }

  /**
   * 支付详情信息
   */
  export function paymentInfo(code: string) {
    return HYNetworkManager.sendRequest(
      new HYNetworkRequest({
        businessId,
        method: "GET",
        path: UserURL.paymentInfo,
        data: { outTradeNo: code },
        converter: (data) => new PaymentInfoModel(data)
      })
    ).response;
  }

  /**
   * 支付 - 支付宝
   */
  export function paymentAlipay(params: IPaymentAlipayParam) {
    return HYNetworkManager.sendRequest(new HYNetworkRequest({
      businessId,
      method: "POST",
      path: UserURL.paymentAlipay,
      data: params,
      converter: (data) => new PaymentAlipayModel(data)
    })).response;
  }
}

export default UserApi;
