import { IHYNetworkRequestHandler } from "@hy-sdk/framework";
import axios from "axios";

export const hyNetworkInstance: IHYNetworkRequestHandler = {
  async request({
    method,
    header,
    url,
    data,
    timeout,
    onUploadProgress,
    onDownloadProgress,
  }) {
    if (method === "GET") {
      return axios.request({
        method: "GET",
        headers: header,
        url,
        params: data,
        timeout,
        onUploadProgress,
        onDownloadProgress,
      });
    }

    return axios.request({
      method: method as any,
      headers: header,
      url,
      data,
      timeout,
      onUploadProgress,
      onDownloadProgress,
    });
  },
};
