namespace OthersURL {

  const frontPrefix = "/frontend";

  /**
   * 通用杂项
   */
  export namespace Common {

    const prefix = "/common";

    // POST 意见反馈
    export const feedback = prefix + "/feedback";
    // GET 获取文章sitemap
    export const siteMapArticle = prefix + "/article/list-sitemap";
  }

  /**
   * 信息服务
   */
  export namespace Info {
    const prefix = frontPrefix + "/information";

    // POST 商务合作
    export const cooperation = prefix + "/cooperation";
  }

  // GET 真假客户验证
  export function checkIfReal (code: string) {
    return frontPrefix + "/check-real-user/" + code;
  }
  // GET 获取假数据
  export const fakeData = frontPrefix + "/calc-data";
  // GET 获取销售团队信息
  export const saleTeam = frontPrefix + "/home-page-sale-team";
  // GET 获取友情链接
  export const webLink = frontPrefix + "/web-link/list";
}

export default OthersURL;
